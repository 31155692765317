import React, { useEffect, useState } from "react";
import { fetchNextMonthTuition } from "../../services/paymentService";
import { FaCalendarAlt, FaMoneyBillWave } from "react-icons/fa";

const NextMonthTuition = () => {
  const [loading, setLoading] = useState(true);
  const [tuitionData, setTuitionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const loadTuitionData = async () => {
      try {
        const data = await fetchNextMonthTuition();
        console.log(data);
        setTuitionData(data);
      } catch (error) {
        setErrorMessage("수업료 데이터를 불러오는 중 오류가 발생했습니다.");
      } finally {
        setLoading(false);
      }
    };

    loadTuitionData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-red-500 text-xl font-semibold">{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-r from-blue-100 to-blue-300 p-8">
      <h2 className="text-4xl font-extrabold mb-8 text-gray-800 flex items-center">
        <FaCalendarAlt className="mr-2" /> 다음 달 수업료 예측
      </h2>
      <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-5xl transform hover:scale-105 transition-transform duration-300">
        <h3 className="text-3xl font-bold text-blue-600 mb-6 flex items-center">
          <FaMoneyBillWave className="mr-2" /> 다음 달 총 수업료
        </h3>
        <div className="mt-4 space-y-4">
          <p className="text-lg">
            <span className="font-medium text-gray-700">예상 총 수업료:</span> <span className="font-semibold text-gray-900">{tuitionData.totalNextMonthTuition.toLocaleString()} 원</span>
          </p>
          <div className="mt-8">
            <h4 className="text-2xl font-semibold text-gray-700 mb-4">강의별 상세 내역</h4>
            {tuitionData.courseDetails.map((course, index) => (
              <div key={index} className="p-4 mb-4 border rounded-lg bg-gray-50">
                <h5 className="text-xl font-bold text-gray-800">{course.courseName}</h5>
                <p className="text-lg">학생 수: {course.students.length}</p>
                <p className="text-lg">예상 수업 횟수: {course.numberOfSessions}</p>
                <p className="text-lg">
                  <span className="font-semibold text-gray-900">예상 수업료:</span> <span className="font-semibold text-gray-900">{course.tuition.toLocaleString()} 원</span>
                </p>
                <div className="mt-4">
                  <h6 className="text-lg font-semibold text-gray-700 mb-2">예상 수업 일자, 요일 및 시작 시간</h6>
                  <ul className="space-y-2">
                    {course.sessionDates.map((session, idx) => (
                      <li key={idx} className="text-gray-600">
                        <span className="font-medium text-gray-800">{session.date}</span> ({session.dayOfWeek}) - <span className="font-medium text-gray-800">{session.startTime}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextMonthTuition;
