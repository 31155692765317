// src/services/paymentService.js

import axios from "../utils/axiosInstance"; // axiosInstance 설정이 되어 있어야 함

// 납부 내역 생성
export const createPayment = async (paymentData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post("/api/payments", paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("납부 내역 생성 중 오류 발생:", error);
    throw error.response.data.message || "납부 내역 생성 실패";
  }
};

// 납부 내역 조회
export const fetchPayments = async (page = 1, limit = 15, courseId = "", start, end, status = "") => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`/api/payments?page=${page}&limit=${limit}&status=${status}&courseId=${courseId}&startDate=${start}&endDate=${end}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("납부 내역 조회 중 오류 발생:", error);
    throw error.response.data.message || "납부 내역 조회 실패";
  }
};

// 교사 또는 학생의 특정 납부 내역 조회
export const fetchPaymentById = async (paymentId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`/api/payments/${paymentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("납부 내역 상세 조회 중 오류 발생:", error);
    throw error.response.data.message || "납부 내역 상세 조회 실패";
  }
};

// 납부 내역 수정
export const updatePayment = async (paymentId, status) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch(`/api/payments/${paymentId}`, status, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("납부 내역 수정 중 오류 발생:", error);
    throw error.response.data.message || "납부 내역 수정 실패";
  }
};

// 납부 내역 삭제
export const deletePayment = async (paymentId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(`/api/payments/${paymentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("납부 내역 삭제 중 오류 발생:", error);
    throw error.response.data.message || "납부 내역 삭제 실패";
  }
};

// 소득 정보를 가져오는 함수
export const fetchIncome = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get("/api/payments/income", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // 소득 데이터를 반환
  } catch (error) {
    console.error("소득 데이터를 불러오는 중 오류 발생:", error);
    throw error; // 에러를 호출하는 쪽에서 처리할 수 있도록 throw
  }
};

export const fetchNextMonthTuition = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get("/api/payments/next-month", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
