// src/contexts/AppContext.js
import React, { createContext, useContext, useRef, useState, useEffect } from "react";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  // 인증 관련 상태
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [webSocketInstance, setWebSocketInstance] = useState(null);

  // 페이지 함수 관리용 ref 객체
  const pageFunctions = useRef({});

  // 특정 페이지의 함수를 저장
  const setPageFunction = (page, func) => {
    pageFunctions.current[page] = func;
  };

  const executePageFunction = (path, data) => {
    console.log("executePageFunction", path, data);
    for (const pattern in pageFunctions.current) {
      if (matchPath(pattern, path)) {
        const func = pageFunctions.current[pattern];
        if (func) {
          func(data);
          return;
        }
      }
    }
    console.warn("executePageFunction", path, data);
    console.warn(`No function found for the page: ${path}`);
  };

  const matchPath = (pattern, path) => {
    const regex = new RegExp(`^${pattern.replace(/:\w+/g, "\\w+")}$`);
    return regex.test(path);
  };

  // 로그인 기능
  const login = (newToken, newUserRole) => {
    localStorage.setItem("token", newToken);
    localStorage.setItem("role", newUserRole);
    setToken(newToken);
    setUserRole(newUserRole);
  };

  // 로그아웃 기능
  const logout = () => {
    if (webSocketInstance) {
      webSocketInstance.send(JSON.stringify({ type: "logout" })); // WebSocket에 로그아웃 메시지 전송
      webSocketInstance.close(); // 로그아웃 시 WebSocket 연결 닫기
    }
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setToken(null);
    setUserRole(null);
  };

  // 토큰과 역할의 변경을 감지하여 상태를 업데이트
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setUserRole(localStorage.getItem("role"));
  }, []);

  return (
    <AppContext.Provider
      value={{
        token,
        userRole,
        login,
        logout,
        setWebSocketInstance,
        setPageFunction,
        executePageFunction,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
