import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getQnaDetails, updateQnaStatus, downloadQnaFile, downloadQnaCommentFile } from "../../services/qnaService";
import QnaHeader from "../../components/Qna/Detail/QnaHeader";
import QnaAttachments from "../../components/Qna/Detail/QnaAttachments";
import QnaComments from "../../components/Qna/Detail/QnaComments";
import FileLink from "../../components/FileLink";
import { useAppContext } from "../../contexts/AppContext";

const QnaDetail = () => {
  const { qnaId } = useParams();
  const [qnaData, setQnaData] = useState(null);
  const [isQnaClosed, setIsQnaClosed] = useState(false);
  const { setPageFunction } = useAppContext();

  const fetchQnaData = async () => {
    try {
      const data = await getQnaDetails(qnaId);
      setQnaData(data);
      setIsQnaClosed(data.status === "closed");
    } catch (error) {
      console.error("Failed to fetch QnA details:", error);
    }
  };

  useEffect(() => {
    fetchQnaData();
    setPageFunction("/qnas/:qnaId", (data) => {
      if (data.id === qnaId) {
        fetchQnaData();
      }
    });
  }, [qnaId, setPageFunction]);

  const handleQnaClose = async () => {
    try {
      const updatedQna = await updateQnaStatus(qnaId, "closed");
      setQnaData(updatedQna);
      setIsQnaClosed(true);
    } catch (error) {
      alert(error.response?.data.message ?? "오류가 발생했습니다.");
      console.error("Failed to close QnA:", error);
    }
  };

  return (
    <div className="qna-detail-page max-w-6xl mx-auto mt-8 p-6 bg-white shadow-lg rounded-lg">
      {qnaData ? (
        <>
          <QnaHeader qnaData={qnaData} />
          <QnaAttachments qnaId={qnaId} files={qnaData.files} onDownload={downloadQnaFile} />
          <QnaComments answers={qnaData.answers} isQnaClosed={isQnaClosed} qnaId={qnaId} onQnaClose={handleQnaClose} setQnaData={setQnaData} onDownload={downloadQnaCommentFile} />
        </>
      ) : (
        <p className="text-center text-gray-600 animate-pulse">로딩 중...</p>
      )}
    </div>
  );
};

export default QnaDetail;
