import React from "react";
import { Link } from "react-router-dom";

const QnaItem = ({ qna, handleDelete }) => {
  return (
    <li className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 border border-gray-200">
      <Link to={`/qnas/${qna._id}`} className="block">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold text-gray-800 leading-snug">
            {qna.question}
            <span className="text-sm text-gray-500 ml-2">({qna.course.courseName})</span>
          </h3>
          <span className={`px-2 py-1 rounded-full text-xs font-medium ${qna.status === "closed" ? "bg-red-100 text-red-600" : "bg-green-100 text-green-600"}`}>
            {qna.status === "closed" ? "완료" : "진행 중"}
          </span>
        </div>
        <div className="text-gray-600 text-sm mb-4">
          <p className="mb-1">댓글 수: {qna.answers.length}</p>
          <p>
            작성자: <span className="font-medium">{qna.createdBy.name}</span> | 등록일: <span>{new Date(qna.createdAt).toLocaleString()}</span>
          </p>
          {qna.hashtags.length > 0 && <p className="text-xs mt-2 text-gray-500">해시태그: {qna.hashtags.join(", ")}</p>}
        </div>
      </Link>

      {localStorage.getItem("role") === "teacher" && (
        <button
          onClick={() => handleDelete(qna._id)}
          className="w-full text-sm text-white-600 border border-red-600 hover:bg-red-600 hover:text-white py-2 rounded-md font-medium transition-all duration-200 mt-3"
        >
          삭제
        </button>
      )}
    </li>
  );
};

export default QnaItem;
