// src/components/Category/CategoryModal.js

import React, { useState, useEffect } from "react";
import Modal from "../Modal";

const CategoryModal = ({ isOpen, onClose, onSubmit, selectedCategory }) => {
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (selectedCategory) {
      setCategoryName(selectedCategory.name);
    } else {
      setCategoryName("");
    }
  }, [selectedCategory]);

  const handleSubmit = () => {
    if (!categoryName.trim()) {
      alert("카테고리 이름을 입력하세요.");
      return;
    }
    onSubmit({ name: categoryName });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h3 className="text-xl font-bold mb-4">{selectedCategory ? "카테고리 수정" : "새 카테고리 추가"}</h3>
      <input type="text" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} placeholder="카테고리 이름" className="border rounded px-2 py-1 w-full mb-2" />
      <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">
        {selectedCategory ? "수정" : "추가"}
      </button>
    </Modal>
  );
};

export default CategoryModal;
