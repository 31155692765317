import React, { useEffect, useState } from "react";
import { fetchProposals, updateProposalStatus } from "../../services/proposalService";
import Modal from "../../components/Modal";
import { getUserRole } from "../../utils/authUtils";
import { fetchUserInfo } from "../../services/userService";
import { useNavigate } from "react-router-dom"; // 페이지 이동을 위해 useNavigate 사용

const ProposalList = () => {
  const [proposals, setProposals] = useState([]);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const userRole = getUserRole(); // 사용자 역할을 가져옴
  const navigate = useNavigate();

  useEffect(() => {
    const getProposals = async () => {
      try {
        const data = await fetchProposals();
        setProposals(data);
      } catch (error) {
        setError(error.message);
      }
    };

    getProposals();
  }, []);

  const handleOpenModal = async (proposal) => {
    try {
      const userInfo =
        userRole === "teacher"
          ? await fetchUserInfo(proposal.student.user._id) // 학생 정보 가져오기
          : await fetchUserInfo(proposal.course.teacher.user._id); // 강사 정보 가져오기

      setSelectedProposal({ proposal: proposal, userInfo: userInfo });
      setModalOpen(true);
    } catch (error) {
      console.error(error);
      setError("사용자 정보를 불러오는 중 오류 발생");
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProposal(null);
  };

  const handleUpdateStatus = async (status) => {
    try {
      await updateProposalStatus(selectedProposal.proposal._id, status);
      const updatedProposals = proposals.map((p) => (p._id === selectedProposal.proposal._id ? { ...p, status } : p));
      setProposals(updatedProposals);
      handleCloseModal();
    } catch (error) {
      setError("제안 상태를 업데이트하는 중 오류 발생");
    }
  };

  if (error) {
    return <p className="text-center text-red-500">Error: {error}</p>;
  }

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg mt-10 w-full max-w-7xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">제안 목록</h2>
        {proposals.length === 0 ? (
          <p className="text-center text-gray-600">등록된 제안이 없습니다.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto bg-white border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-3 px-4 border-b text-left text-gray-700 font-medium">강의명</th>
                  <th className="py-3 px-4 border-b text-left text-gray-700 font-medium">{userRole === "teacher" ? "학생명" : "강사명"}</th>
                  <th className="py-3 px-4 border-b text-left text-gray-700 font-medium">제안 상태</th>
                  <th className="py-3 px-4 border-b text-left text-gray-700 font-medium">제안 일시</th>
                  <th className="py-3 px-4 border-b text-left text-gray-700 font-medium">상세 보기</th>
                </tr>
              </thead>
              <tbody>
                {proposals.map((proposal) => (
                  <tr key={proposal._id} className="bg-white hover:bg-gray-100">
                    <td className="py-3 px-4 border-b">{proposal.course.courseName}</td>
                    <td className="py-3 px-4 border-b">{userRole === "teacher" ? proposal.student.user.name : proposal.course.teacher.user.name}</td>
                    <td className="py-3 px-4 border-b">{proposal.status}</td>
                    <td className="py-3 px-4 border-b">{new Date(proposal.proposedAt).toLocaleString()}</td>
                    <td className="py-3 px-4 border-b">
                      <button onClick={() => handleOpenModal(proposal)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">
                        상세 보기
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {userRole === "teacher" && (
        // 제안하기 버튼 - 버튼 스타일을 CourseList와 통일
        <div className="flex justify-end mt-6">
          <button
            onClick={() => navigate("/create-proposal")} // 제안 생성 페이지로 이동
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            제안하기
          </button>
        </div>
      )}

      {/* 모달 창 */}
      {modalOpen && selectedProposal && (
        <Modal isOpen={modalOpen} onClose={handleCloseModal}>
          <div className="p-6">
            <h2 className="text-xl font-bold mb-4">제안 상세 정보</h2>
            <p>
              <strong>강의명:</strong> {selectedProposal.proposal.course.courseName}
            </p>
            <p>
              <strong>제안 상태:</strong> {selectedProposal.proposal.status}
            </p>
            <p>
              <strong>{userRole === "teacher" ? "학생 정보" : "강사 정보"}:</strong>{" "}
              {userRole === "teacher"
                ? selectedProposal.proposal.student.user.name + ", " + selectedProposal.proposal.student.user.email
                : selectedProposal.proposal.course.teacher.user.name + ", " + selectedProposal.proposal.course.teacher.user.email}
            </p>

            {/* 학생일 경우에만 수락/거절 버튼 표시 */}
            {userRole === "student" && selectedProposal.proposal.status === "pending" && (
              <div className="mt-4">
                <button onClick={() => handleUpdateStatus("approved")} className="mr-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                  수락
                </button>
                <button onClick={() => handleUpdateStatus("rejected")} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                  거절
                </button>
              </div>
            )}
            <button onClick={handleCloseModal} className="mt-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
              닫기
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProposalList;
