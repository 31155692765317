import React, { useEffect, useState } from "react";
import { fetchIncome } from "../../services/paymentService";
import { FaRegCalendarAlt, FaMoneyBillWave } from "react-icons/fa";

const IncomeReport = () => {
  const [incomeData, setIncomeData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadIncome = async () => {
      try {
        const data = await fetchIncome();
        setIncomeData(data);
      } catch (error) {
        console.error("소득 데이터를 불러오는 중 오류 발생:", error);
      } finally {
        setLoading(false);
      }
    };

    loadIncome();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-r from-blue-100 to-blue-300 p-8">
      <h2 className="text-4xl font-extrabold mb-8 text-gray-800">소득 보고서</h2>
      <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-5xl transform hover:scale-105 transition-transform duration-300">
        <h3 className="text-3xl font-bold mb-6 text-blue-600 flex items-center">
          <FaRegCalendarAlt className="mr-2" /> 월별 소득
        </h3>
        {incomeData.incomeByMonth &&
          Object.keys(incomeData.incomeByMonth).map((year) => (
            <div key={year} className="mb-6">
              <h4 className="text-xl font-semibold mb-2">{year}년</h4>
              <ul className="space-y-2">
                {Object.keys(incomeData.incomeByMonth[year]).map((month) => (
                  <li key={month} className="flex justify-between p-2 rounded-lg hover:bg-gray-100 transition duration-200">
                    <span className="text-gray-700">{month}월</span>
                    <span className="font-medium text-gray-900">{incomeData.incomeByMonth[year][month].toLocaleString()} 원</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        <div className="mt-8 border-t-2 pt-6">
          <h3 className="text-3xl font-bold text-green-600 flex items-center">
            <FaMoneyBillWave className="mr-2" /> 총 소득
          </h3>
          <div className="mt-4 space-y-2">
            <p className="text-lg">
              <span className="font-medium text-gray-700">올해 소득:</span> {incomeData.totalIncome.year.toLocaleString()} 원
            </p>
            <p className="text-lg">
              <span className="font-medium text-gray-700">이번 달 소득:</span> {incomeData.totalIncome.month.toLocaleString()} 원
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeReport;
