// src/contexts/WebSocketProvider.js
import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { useAppContext } from "./AppContext";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { token, setWebSocketInstance } = useAppContext();
  const [userCount, setUserCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const wsRef = useRef(null);
  const retryTimeout = useRef(null); // 재연결 시도 타이머
  const pingInterval = useRef(null); // Ping-Pong 인터벌

  const connectWebSocket = () => {
    if (!token) return;

    wsRef.current = new WebSocket(`wss://server.thec0d1ng.com?token=${token}`, "user-protocol");

    wsRef.current.onopen = () => {
      console.log("WebSocket connection established.");
      setWebSocketInstance(wsRef.current);

      // Ping-Pong 시작
      pingInterval.current = setInterval(() => {
        if (wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ type: "ping" })); // Ping 전송
        }
      }, 30000); // 30초마다 Ping 전송

      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current); // 재연결 타이머 초기화
        retryTimeout.current = null;
      }
    };

    wsRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "pong") {
        console.log("Pong received from server"); // 서버로부터 Pong 수신 시 로그 기록
      }
      if (message.type === "userList") {
        setUserCount(message.userCount);
        setUserList(message.userList);
      }
    };

    wsRef.current.onclose = () => {
      console.log("WebSocket connection closed.");
      setWebSocketInstance(null);
      clearInterval(pingInterval.current); // 연결이 끊기면 Ping-Pong 중지
      retryWebSocketConnection(); // 연결이 닫힐 때마다 재연결 시도
    };

    wsRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      wsRef.current.close();
    };
  };

  const retryWebSocketConnection = () => {
    if (!retryTimeout.current) {
      retryTimeout.current = setTimeout(() => {
        console.log("Attempting WebSocket reconnection...");
        connectWebSocket(); // 재연결 시도
      }, 3000); // 3초 후 재연결 시도
    }
  };

  useEffect(() => {
    if (token) {
      connectWebSocket();
    }
    return () => {
      wsRef.current?.close();
      clearTimeout(retryTimeout.current);
      clearInterval(pingInterval.current); // 컴포넌트 unmount 시 Ping-Pong 중지
    };
  }, [token]);

  return <WebSocketContext.Provider value={{ userCount, userList }}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => useContext(WebSocketContext);
