import React, { useState, useEffect } from "react";
import { addClassComment, fetchClassComments, downloadCommentFile, deleteClassComment } from "../../services/classService";

const ClassComments = ({ classId, currentUser }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // 댓글 불러오기
    const loadComments = async () => {
      try {
        const fetchedComments = await fetchClassComments(classId);
        setComments(fetchedComments);
      } catch (error) {
        console.error("댓글 불러오기 실패:", error);
      }
    };

    loadComments();
  }, [classId]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;

    try {
      const updatedComments = await addClassComment(classId, newComment, selectedFile);
      setComments(updatedComments);
      setNewComment("");
      setSelectedFile(null);
    } catch (error) {
      console.error("댓글 추가 실패:", error);
    }
  };

  const handleFileDownload = async (commentId, fileName) => {
    try {
      const fileData = await downloadCommentFile(classId, commentId);
      const blob = new Blob([fileData], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "comment-file";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("파일 다운로드 실패:", error);
      alert("파일 다운로드에 실패했습니다.");
    }
  };

  const handleCommentDelete = async (commentId) => {
    try {
      const updatedComments = await deleteClassComment(classId, commentId);
      setComments(updatedComments);
    } catch (error) {
      alert(error.response?.data?.message || "댓글 삭제 실패");
      console.error("댓글 삭제 실패:", error);
    }
  };

  const canDeleteComment = (createdAt) => {
    const currentTime = Date.now();
    const commentTime = new Date(createdAt).getTime();
    return (currentTime - commentTime) / (1000 * 60) <= 3;
  };

  return (
    <div className="mt-6 max-h-[800px] flex flex-col bg-gray-100 rounded-lg shadow-lg overflow-hidden border border-gray-200">
      <div className="bg-white p-4 shadow-md">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="댓글을 입력하세요"
          className="w-full border rounded p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition"
          rows={3}
        />
        <input type="file" onChange={handleFileChange} className="mt-2 block w-full text-gray-700" />
        <button onClick={handleCommentSubmit} className="mt-3 w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition">
          댓글 달기
        </button>
      </div>
      <div className="overflow-y-auto p-4 flex-1 bg-gray-50">
        <ul className="space-y-4">
          {comments.map((comment) => (
            <li key={comment._id} className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold text-gray-800">{comment.user.name}</p>
                {canDeleteComment(comment.createdAt) && (
                  <button onClick={() => handleCommentDelete(comment._id)} className="rounded-full hover:bg-red-100 p-1 transition duration-300 ease-in-out">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5 text-red-500">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
              </div>
              <p className="text-gray-700 mt-1 whitespace-pre-line text-start">{comment.content}</p>
              {comment.file && (
                <div className="mt-2 text-start">
                  <button onClick={() => handleFileDownload(comment._id, comment.file)} className="text-blue-500 underline hover:text-blue-700">
                    첨부파일 다운로드
                  </button>
                </div>
              )}
              <p className="text-xs text-gray-500 mt-2 text-start">{new Date(comment.createdAt).toLocaleString()}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ClassComments;
