import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { format, parse, startOfWeek, getDay, addDays, endOfMonth, differenceInMinutes, addMinutes } from "date-fns";
import { getHourMinute } from "../../utils/dateUtils";
import ko from "date-fns/locale/ko";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { fetchCourses } from "../../services/courseService";
import { createPayment } from "../../services/paymentService";
import { fetchStudentSchedules } from "../../services/scheduleService";
import Modal from "../../components/Modal";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// 로컬라이저 설정
const locales = { ko };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const formatDate = (date) => {
  const d = new Date(date);
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")}`;
};

const CreatePaymentStudent = () => {
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [paymentDate, setPaymentDate] = useState(formatDate(new Date()));
  const [classStartDate, setClassStartDate] = useState(formatDate(new Date()));
  const [classEndDate, setClassEndDate] = useState(formatDate(endOfMonth(new Date())));
  const [amount, setAmount] = useState("");
  const [payerName, setPayerName] = useState("");
  const [calculatedSessions, setCalculatedSessions] = useState(0);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [estimatedMinutes, setEstimatedMinutes] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null); // 클릭한 이벤트 저장
  const [modalOpen, setModalOpen] = useState(false); // 이벤트 수정 모달 관리
  const [tempEvent, setTempEvent] = useState(null);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
      } catch (error) {
        console.error("강의 목록 불러오기 실패:", error);
      }
    };

    loadCourses();
  }, []);

  useEffect(() => {
    fetchSchedules(selectedDate); // 스케줄 가져오기
    if (selectedCourse) {
      generateCalendarEvents(new Date(classStartDate), new Date(classEndDate));
    }
  }, [selectedCourse, classStartDate, selectedDate]);

  // 강의별 색상 구분
  const eventPropGetter = (event) => {
    let backgroundColor = event?.course?._id === (selectedCourse?._id ?? "") ? "#0000ff" : event?.course === undefined ? "#00dddd" : "#ccc2d6"; // 강의별로 색상 구분
    // if (event.status === "pending-modification") {
    //   backgroundColor = "#f39c12"; // 수정 요청 중인 일정은 오렌지색
    // }
    return { style: { backgroundColor } };
  };

  // 스케줄 데이터를 가져오는 함수
  const fetchSchedules = async (selectedDate) => {
    try {
      const data = await fetchStudentSchedules(selectedDate);
      setSchedules(data);
    } catch (error) {
      console.error("스케줄 불러오기 실패:", error);
    }
  };

  // 선택된 날짜가 변경되면 스케줄을 다시 불러옴
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    const course = courses.find((c) => c._id === selectedCourseId);
    setSelectedCourse(course);
  };

  const handleAmountChange = (e) => {
    const inputAmount = e.target.value;
    setAmount(inputAmount);

    if (selectedCourse) {
      const calculated = selectedCourse.costType === "perHour" ? Math.floor(inputAmount / selectedCourse.costPerHour) : Math.floor(inputAmount / selectedCourse.costPerSession);
      setCalculatedSessions(calculated);
    }
  };

  const handleClassStartDateChange = (e) => {
    const startDate = new Date(e.target.value);
    setClassStartDate(formatDate(startDate));
    generateCalendarEvents(startDate, classEndDate);
  };

  const handleClassEndDateChange = (e) => {
    const endDate = new Date(e.target.value);
    setClassEndDate(formatDate(endDate));
    generateCalendarEvents(classStartDate, endDate);
  };

  const generateCalendarEvents = (startDate, endDate) => {
    if (!selectedCourse || !selectedCourse.schedule) return;

    const lastDateOfMonth = new Date(endDate);
    const events = [];
    let currentDate = new Date(startDate);
    let totalMinutes = 0;
    let totalPrice = 0;

    while (currentDate <= lastDateOfMonth) {
      const dayOfWeek = getDay(currentDate);
      const dayOfWeekString = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek];

      const matchingSchedule = selectedCourse.schedule.find((schedule) => schedule.dayOfWeek === dayOfWeekString);
      if (matchingSchedule) {
        const [startHours, startMinutes] = matchingSchedule.startTime.split(":").map(Number);
        const startEvent = new Date(currentDate);
        startEvent.setHours(startHours, startMinutes);

        const endEvent = new Date(startEvent);
        endEvent.setMinutes(startEvent.getMinutes() + matchingSchedule.duration);

        totalMinutes += matchingSchedule.duration;
        totalPrice += selectedCourse.costType === "perHour" ? (matchingSchedule.duration / 60) * selectedCourse.costPerHour : selectedCourse.costPerSession;

        events.push({
          id: currentDate.getTime(),
          title: `${matchingSchedule.duration}분 수업`,
          start: startEvent,
          end: endEvent,
          color: selectedCourse.colorCode,
          isNew: true,
        });
      }
      currentDate = addDays(currentDate, 1);
    }

    setEstimatedMinutes(totalMinutes);
    setEstimatedPrice(totalPrice);
    setCalendarEvents(events);
  };

  const handleSelectEvent = (event) => {
    if (!event?.isNew) return; // course 정보가 없는 경우 모달 열기 금지
    setSelectedEvent(event);
    setTempEvent(event);
    setModalOpen(true);
  };

  const handleEventDrop = ({ event, start, end }) => {
    console.log(event);
    if (!event?.isNew) return; // course 정보가 없는 경우 모달 열기 금지
    const updatedEvents = calendarEvents.map((ev) => (ev.id === event.id ? { ...ev, start, end } : ev));
    setCalendarEvents(updatedEvents);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
    setTempEvent(null);
  };

  const handleEventChange = (key, value) => {
    if (key === "start") {
      // 기존 이벤트의 지속 시간(duration)을 계산하여 종료 시간을 자동으로 설정
      const durationInMinutes = differenceInMinutes(new Date(tempEvent.end), new Date(tempEvent.start));
      const newEnd = addMinutes(new Date(value), durationInMinutes); // 시작 시간에 지속 시간을 더해 종료 시간 설정

      setTempEvent((prev) => ({ ...prev, start: value, end: newEnd }));
    } else {
      setTempEvent((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleEventUpdate = () => {
    const updatedEvents = calendarEvents.map((ev) => (ev.id === selectedEvent.id ? tempEvent : ev));
    setCalendarEvents(updatedEvents);
    handleCloseModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // calendarEvents에서 start와 end만 추출
    const schedules = calendarEvents.map((event) => ({
      start: event.start,
      end: event.end,
    }));

    const paymentData = {
      courseId: selectedCourse._id,
      amount,
      // studentId,
      paymentDate,
      payerName,
      schedules,
      totalSessions: calculatedSessions,
    };

    try {
      await createPayment(paymentData);
      alert("납부 내역이 성공적으로 생성되었습니다.");
      navigate("/payments");
    } catch (error) {
      console.error("납부 내역 생성 실패:", error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mx-auto p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6 text-center">납부 내역 생성</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">강의 선택</label>
            <select onChange={handleCourseChange} className="w-full p-2 border rounded">
              <option value="">강의를 선택하세요</option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>
                  {course.courseName}
                </option>
              ))}
            </select>
          </div>

          {selectedCourse && (
            <div className="mb-4">
              <p>
                <strong>강의 비용:</strong> {selectedCourse.costType === "perHour" ? `시간당 ${selectedCourse.costPerHour}` : `회당 ${selectedCourse.costPerSession}`}
              </p>
              <p>
                <strong>수업 요일 및 시간:</strong> {selectedCourse.schedule.map((s) => `${s.dayOfWeek} ${s.startTime}, ${s.duration}분`).join(", ")}
              </p>
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">납부 금액</label>
            <input type="number" value={amount} onChange={handleAmountChange} className="w-full p-2 border rounded" required />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">입금자명</label>
            <input type="text" value={payerName} onChange={(e) => setPayerName(e.target.value)} className="w-full p-2 border rounded" placeholder="입금자명을 입력하세요" required />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">납부 일자</label>
            <input type="date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} className="w-full p-2 border rounded" required />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">수업 기준일 선택</label>
            <input type="date" value={classStartDate} onChange={handleClassStartDateChange} className="w-full p-2 border rounded" required />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">수업 종료일 선택</label>
            <input type="date" value={classEndDate} onChange={handleClassEndDateChange} className="w-full p-2 border rounded" required />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">예상 수업 횟수</label>
            <p>{calculatedSessions}회</p>
          </div>

          {selectedCourse && (
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">예상 수업 시간, 횟수, 비용</label>
              <p>
                {getHourMinute(estimatedMinutes)} , {calendarEvents.length}회 , {estimatedPrice}원
              </p>
            </div>
          )}

          <DragAndDropCalendar
            events={schedules.concat(calendarEvents).map((schedule) => ({
              title: `${schedule.course?.courseName ?? (schedule?.isNew ? "신규 수업" : "개인 일정")}`,
              start: new Date(schedule?.start),
              end: new Date(schedule?.end),
              originalStart: schedule?.originalStart,
              originalEnd: schedule?.originalEnd,
              id: schedule._id ?? schedule.id,
              status: schedule?.status, // 일정 상태 추가
              // color: stringToHexColor(schedule.course.courseName),
              course: schedule?.course,
              modifiedBy: schedule?.modifiedBy,
              modificationRequested: schedule?.modificationRequested,
              isNew: schedule?.isNew ?? false,
            }))}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800 }}
            views={["month", "week"]}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleSelectEvent}
            onEventDrop={handleEventDrop}
            onNavigate={handleDateChange} // 달력 날짜 이동 시 호출
            draggableAccessor={() => true}
            className="mb-6"
          />

          <button type="submit" className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            납부 내역 생성
          </button>

          {/* 이벤트 수정 모달 */}
          {modalOpen && selectedEvent && (
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
              <div className="space-y-6">
                <h3 className="text-xl font-semibold text-gray-700 mb-4">이벤트 수정</h3>

                <div className="flex flex-col space-y-4">
                  <p className="text-lg font-medium text-gray-900">{selectedEvent.title}</p>

                  <div>
                    <label className="block text-left text-sm font-semibold text-gray-600">시작 시간</label>
                    <input
                      type="datetime-local"
                      value={format(tempEvent.start, "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => handleEventChange("start", new Date(e.target.value))}
                      className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition"
                    />
                  </div>

                  <div>
                    <label className="block text-left text-sm font-semibold text-gray-600">종료 시간</label>
                    <input
                      type="datetime-local"
                      value={format(tempEvent.end, "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => handleEventChange("end", new Date(e.target.value))}
                      className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition"
                    />
                  </div>

                  <button onClick={handleEventUpdate} className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition">
                    수정 완료
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </form>
      </div>
    </DndProvider>
  );
};

export default CreatePaymentStudent;
