import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUpcomingClasses, fetchProposals, fetchStudents, fetchOngoingCourses, fetchQnas } from "../../services/teacherService";
import { updateClassNotes } from "../../services/classService";
import Modal from "../../components/Modal";

const TeacherDashboard = () => {
  const navigate = useNavigate();
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [students, setStudents] = useState([]);
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [qnas, setQnas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalClassOpen, setModalClassOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modalCourseOpen, setModalCourseOpen] = useState(false);
  const userRole = localStorage.getItem("role"); // 사용자 역할 확인

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [upcomingClassesData, proposalsData, studentsData, ongoingCoursesData, qnasData] = await Promise.all([
          fetchUpcomingClasses(),
          fetchProposals(),
          fetchStudents(),
          fetchOngoingCourses(),
          fetchQnas(),
        ]);

        setUpcomingClasses(upcomingClassesData);
        setProposals(proposalsData);
        setStudents(studentsData);
        setOngoingCourses(ongoingCoursesData);
        setQnas(qnasData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewClassList = (courseId) => {
    navigate(`/class-list/${courseId}`); // 수업 목록 페이지로 이동
  };

  const handleOpenModalClass = (classData) => {
    setSelectedClass(classData);
    setModalClassOpen(true);
  };

  const handleCloseModalClass = () => {
    setModalClassOpen(false);
    setSelectedClass(null);
  };

  const handleOpenCourseModal = async (course) => {
    setSelectedCourse(course);
    setModalCourseOpen(true);
  };

  const handleCloseCourseModal = () => {
    setModalCourseOpen(false);
    setSelectedCourse(null);
  };

  const handleSaveNotes = async () => {
    const { _id } = selectedClass; // 선택된 클래스 ID 가져오기
    const teacherNote = selectedClass.notes.teacherNote; // 교강사 노트
    const lectureNote = selectedClass.notes.lectureNote; // 강의 노트
    const studentNote = selectedClass.notes.studentNote; // 학생 노트

    try {
      await updateClassNotes(_id, { teacherNote, lectureNote, studentNote }); // API 호출
      alert("노트가 저장되었습니다.");
      setUpcomingClasses(upcomingClasses.map((cls) => (cls._id === selectedClass._id ? selectedClass : cls)));
      handleCloseModalClass(); // 모달 닫기
    } catch (error) {
      console.error("노트 저장 실패:", error);
      alert("노트 저장에 실패했습니다.");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <p className="text-xl font-semibold text-gray-700">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <p className="text-xl font-semibold text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-300 py-10">
      <div className="max-w-6xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        {/* 다가오는 수업 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">다가오는 수업</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {upcomingClasses.length > 0 ? (
              upcomingClasses.map((cls) => (
                <div key={cls._id} className="bg-blue-50 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-blue-600">날짜:</strong> {new Date(cls.schedule.start).toLocaleDateString()} <br />
                  <strong className="text-blue-600">시간:</strong> {new Date(cls.schedule.start).toLocaleTimeString()} <br />
                  <strong className="text-blue-600">수업명:</strong> {cls.course.courseName}
                  <div className="flex justify-end mt-4">
                    <button onClick={() => handleOpenModalClass(cls)} className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded transition">
                      상세 보기
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-600">등록된 다가오는 수업이 없습니다.</p>
            )}
          </div>
        </section>

        {/* 진행 중인 강의 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">진행 중인 강의</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {ongoingCourses.length > 0 ? (
              ongoingCourses.map((course) => (
                <div key={course._id} className="bg-purple-50 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-purple-600">강의명:</strong> {course.courseName}
                  <div className="flex justify-end mt-4 space-x-2">
                    <button onClick={() => handleViewClassList(course._id)} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-150">
                      강의실
                    </button>

                    <button onClick={() => handleOpenCourseModal(course)} className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition duration-150">
                      상세 보기
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-600">진행 중인 강의가 없습니다.</p>
            )}
          </div>
        </section>

        {/* 학생 목록 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">학생 목록</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {students.length > 0 ? (
              students.map((student) => (
                <div key={student._id} className="bg-green-50 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-green-600">{student.user.name}</strong> <br />
                  <span className="text-gray-600">{student.user.email}</span>
                </div>
              ))
            ) : (
              <p className="text-gray-600">등록된 학생이 없습니다.</p>
            )}
          </div>
        </section>

        {/* 제안 중인 내역 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">제안 중인 내역</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {proposals.length > 0 ? (
              proposals.map((proposal) => (
                <div key={proposal._id} className="bg-yellow-50 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-yellow-600">강의명:</strong> {proposal.course.courseName} <br />
                  <strong className="text-yellow-600">학생:</strong> {proposal.student.user.name} <br />
                  <strong className="text-yellow-600">상태:</strong> {proposal.status}
                </div>
              ))
            ) : (
              <p className="text-gray-600">제안 중인 내역이 없습니다.</p>
            )}
          </div>
        </section>

        {/* 진행 중인 Q&A 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">진행 중인 Q&A</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {qnas.length > 0 ? (
              qnas.map((qna) => (
                <div key={qna._id} className="bg-indigo-50 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-indigo-600">질문:</strong> {qna.question} <br />
                  <span className="text-gray-600">{qna.course.courseName}</span>
                </div>
              ))
            ) : (
              <p className="text-gray-600">진행 중인 Q&A가 없습니다.</p>
            )}
          </div>
        </section>
      </div>

      {/* 상세 모달 */}
      {modalClassOpen && selectedClass && (
        <Modal isOpen={modalClassOpen} onClose={handleCloseModalClass} width="6xl">
          <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">수업 상세 정보</h2>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-600">강의명:</strong>
                <p className="text-gray-900">{selectedClass.course.courseName}</p>
              </div>
              <div>
                <strong className="text-gray-600">강의 노트:</strong>
                <textarea
                  className="w-full border rounded p-2"
                  defaultValue={selectedClass.notes.lectureNote}
                  onChange={(e) => {
                    const updatedClass = { ...selectedClass };
                    updatedClass.notes.lectureNote = e.target.value;
                    setSelectedClass(updatedClass);
                  }}
                />
              </div>
              <div>
                <strong className="text-gray-600">교강사 노트:</strong>
                <textarea
                  className="w-full border rounded p-2"
                  defaultValue={selectedClass.notes.teacherNote}
                  onChange={(e) => {
                    const updatedClass = { ...selectedClass };
                    updatedClass.notes.teacherNote = e.target.value;
                    setSelectedClass(updatedClass);
                  }}
                />
              </div>
              <div>
                <strong className="text-gray-600">과제:</strong>
                {selectedClass.assignments.length > 0 ? (
                  selectedClass.assignments.map((assignment) => (
                    <div key={assignment._id}>
                      <p>
                        {assignment.title} - {assignment.submitted ? "제출 완료" : "미제출"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-900">과제가 없습니다.</p>
                )}
              </div>
            </div>
            <button onClick={handleSaveNotes} className="mt-2 w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition">
              노트 저장
            </button>
            <button onClick={handleCloseModalClass} className="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
              닫기
            </button>
          </div>
        </Modal>
      )}

      {/* 강의 상세 모달 */}
      {modalCourseOpen && selectedCourse && (
        <Modal isOpen={modalCourseOpen} onClose={handleCloseCourseModal} width="6xl">
          <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">강의 상세 정보</h2>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-600">강의명:</strong>
                <p className="text-gray-900">{selectedCourse.courseName}</p>
              </div>
              <div>
                <strong className="text-gray-600">수업 방식:</strong>
                <p className="text-gray-900">{selectedCourse.courseType}</p>
              </div>
              <div>
                <strong className="text-gray-600">비용 산정 방식:</strong>
                <p className="text-gray-900">{selectedCourse.costType === "perHour" ? `시간당 ${selectedCourse.costPerHour}` : `회당 ${selectedCourse.costPerSession}`}</p>
              </div>
              <div>
                <strong className="text-gray-600">강의 목표:</strong>
                <p className="text-gray-900 whitespace-pre-line text-start">{selectedCourse.courseGoal}</p>
              </div>
              <div>
                <strong className="text-gray-600">커리큘럼:</strong>
                <p className="text-gray-900 whitespace-pre-line text-start">{selectedCourse.curriculum}</p>
              </div>
            </div>
            <button onClick={handleCloseCourseModal} className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
              닫기
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TeacherDashboard;
