import React from "react";
import Modal from "../Modal";
import AssignmentComments from "./AssignmentComments";

const AssignmentCommentModal = ({ modalOpen, setModalOpen, selected, currentUser }) => {
  return (
    <Modal isOpen={modalOpen.commentAssignment} onClose={() => setModalOpen((prev) => ({ ...prev, ["commentAssignment"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-4">
        <h2 className="text-3xl font-semibold text-gray-800 text-center">{selected.assignment.title}</h2>
        <AssignmentComments assignmentId={selected.assignment._id} user={currentUser} />
      </div>
    </Modal>
  );
};

export default AssignmentCommentModal;
