import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchCourseById, updateCourse } from "../../services/courseService"; // 서비스에서 함수 가져오기
import { fetchGoogleCalendars, createGoogleCalendar } from "../../services/googleCalendarService";

const EditCourse = () => {
  const { id } = useParams(); // URL에서 강의 ID 가져오기
  const [courseName, setCourseName] = useState("");
  const [courseEngName, setCourseEngName] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [courseGoal, setCourseGoal] = useState("");
  const [courseType, setCourseType] = useState("online");
  const [costType, setCostType] = useState("perHour");
  const [costPerSession, setCostPerSession] = useState("");
  const [costPerHour, setCostPerHour] = useState("");
  const [isMonthlyPayment, setIsMonthlyPayment] = useState(false); // 월 1회 납부 여부
  const [paymentBasis, setPaymentBasis] = useState("");
  const [schedule, setSchedule] = useState([{ dayOfWeek: "", startTime: "", duration: "" }]);
  const [colorCode, setColorCode] = useState("#3174ad");

  const [googleCalendarName, setGoogleCalendarName] = useState(""); // 선택된 캘린더 이름 상태 추가
  const [googleCalendars, setGoogleCalendars] = useState([]);
  const [selectedCalendarId, setSelectedCalendarId] = useState("");
  const [isCalendarAssigned, setIsCalendarAssigned] = useState(false);
  const [isNewCalendar, setIsNewCalendar] = useState(false);
  const [newCalendarName, setNewCalendarName] = useState("");
  const [newCalendarColor, setNewCalendarColor] = useState("#2952a3");
  const navigate = useNavigate();

  const googleCalendarColors = {
    "1": { code: "#1B887A", name: "청록색" }, // Teal
    "2": { code: "#2952A3", name: "파란색" }, // Blue
    "3": { code: "#28754E", name: "녹색" }, // Green
    "4": { code: "#875509", name: "갈색" }, // Brown
    "5": { code: "#AB8B00", name: "노란색" }, // Yellow
    "6": { code: "#BE6D00", name: "주황색" }, // Orange
    "7": { code: "#B1440E", name: "빨간색" }, // Red
    "8": { code: "#865A5A", name: "분홍색" }, // Pink
    "9": { code: "#705770", name: "보라색" }, // Purple
    "10": { code: "#4E5D6C", name: "회색" }, // Gray
    "11": { code: "#5A6986", name: "진한 회색" }, // Dark Gray
  };
  
  useEffect(() => {
    const fetchCalendars = async () => {
      try {
        const calendars = await fetchGoogleCalendars();
        setGoogleCalendars(calendars);
        console.log(calendars);
        const currentCalendarName = calendars.find((calendar) => calendar.id === selectedCalendarId)?.summary || "";
        setGoogleCalendarName(currentCalendarName);
        console.log(currentCalendarName);
      } catch (error) {
        console.error("Google 캘린더 목록 불러오기 실패:", error);
      }
    };

    const loadCourse = async () => {
      const token = localStorage.getItem("authToken");
      const course = await fetchCourseById(id, token);
      console.log(course.schedule);
      // 수업 정보 상태에 저장
      setCourseName(course.courseName);
      setCourseEngName(course.courseEngName);
      setCurriculum(course.curriculum);
      setCourseGoal(course.courseGoal);
      setCourseType(course.courseType);
      setCostType(course.costType);
      setCostPerSession(course.costPerSession);
      setCostPerHour(course.costPerHour);
      setIsMonthlyPayment(course.isMonthlyPayment);
      setSchedule(course.schedule);
      setPaymentBasis(course.paymentBasis);
      setColorCode(course.colorCode);
      setSelectedCalendarId(course.googleCalendarId || "");
      setIsCalendarAssigned(!!course.googleCalendarId); // 캘린더 할당 여부 설정

      
      fetchCalendars();
    };

    loadCourse();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let googleCalendarId = selectedCalendarId;
    // 새 캘린더 생성
    if (isNewCalendar) {
      try {
        const newCalendar = await createGoogleCalendar(newCalendarName, newCalendarColor);
        googleCalendarId = newCalendar.id;
      } catch (error) {
        console.error("새 캘린더 생성 실패:", error);
        return;
      }
    }

    const courseData = {
      courseName,
      courseEngName,
      curriculum,
      courseGoal,
      courseType,
      costType,
      costPerSession,
      costPerHour,
      isMonthlyPayment, // 월 1회 납부 여부
      schedule,
      paymentBasis,
      colorCode,
    };
    courseData.googleCalendarId = googleCalendarId;

    const response = await updateCourse(id, courseData);
    console.log(response);
    try {
      alert("강의가 성공적으로 수정되었습니다!");
      navigate("/courses");
    } catch (error) {
      alert("강의 수정 실패");
    }
  };

  const addScheduleRow = () => {
    setSchedule([...schedule, { dayOfWeek: "", startTime: "", duration: "" }]);
  };

  const removeScheduleRow = (index) => {
    const newSchedule = schedule.filter((_, i) => i !== index);
    setSchedule(newSchedule);
  };

  const handleScheduleChange = (index, field, value) => {
    const newSchedule = schedule.map((item, i) => (i === index ? { ...item, [field]: value } : item));
    setSchedule(newSchedule);
  };

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg mt-10 w-full max-w-4xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">과외 관리 시스템 - 강의 수정</h2>

        <form onSubmit={handleSubmit}>
          {/* 강의명, 커리큘럼, 강의 목표 */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">강의명:</label>
            <input
              type="text"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">영문 강의명(캘린더에 보일것):</label>
            <input
              type="text"
              value={courseEngName}
              onChange={(e) => setCourseEngName(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">커리큘럼:</label>
            <textarea
              value={curriculum}
              onChange={(e) => setCurriculum(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">강의 목표:</label>
            <textarea
              value={courseGoal}
              onChange={(e) => setCourseGoal(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>

          {/* 수업 방식, 비용 산정 방식 */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">수업 방식:</label>
            <select value={courseType} onChange={(e) => setCourseType(e.target.value)} className="w-full p-2 border border-gray-300 rounded">
              <option value="online">온라인</option>
              <option value="offline">대면</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">비용 산정 방식:</label>
            <select value={costType} onChange={(e) => setCostType(e.target.value)} className="w-full p-2 border border-gray-300 rounded">
              <option value="perHour">시간당</option>
              <option value="perSession">회당</option>
            </select>
          </div>

          {costType === "perSession" ? (
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">수업 회당 비용 (원):</label>
              <input
                type="number"
                value={costPerSession}
                onChange={(e) => setCostPerSession(e.target.value)}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          ) : (
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">시간당 비용 (원):</label>
              <input
                type="number"
                value={costPerHour}
                onChange={(e) => setCostPerHour(e.target.value)}
                required
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          )}

          {/* 월 1회 납부 옵션 추가 */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              <input
                type="checkbox"
                checked={isMonthlyPayment}
                onChange={(e) => setIsMonthlyPayment(e.target.checked)}
                className="mr-2"
              />
              월 1회 납부
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">납부 기준 횟수:</label>
            <input
              type="number"
              value={paymentBasis}
              onChange={(e) => setPaymentBasis(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">강의 색상 선택:</label>
            <div className="flex items-center">
              <input
                type="color"
                value={colorCode}
                onChange={(e) => setColorCode(e.target.value)}
                className="w-16 h-16 p-1 border rounded cursor-pointer" // 버튼 크기를 키우고 마우스 커서를 맞춤
              />
              <span className="ml-4 text-gray-700">현재 선택된 색상: {colorCode}</span>
            </div>
          </div>

          {/* Google 캘린더 선택 또는 보기 */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Google 캘린더</label>
            {isCalendarAssigned ? (
              <p>{googleCalendarName || "기존 캘린더"}</p>
            ) : (
              <select
                value={selectedCalendarId}
                onChange={(e) => setSelectedCalendarId(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Google 캘린더를 선택하세요</option>
                {googleCalendars.map((calendar) => (
                  <option key={calendar.id} value={calendar.id}>
                    {calendar.summary}
                  </option>
                ))}
              </select>
            )}
          </div>

          {/* 새 Google 캘린더 생성 옵션 */}
          {isCalendarAssigned || <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              <input
                type="checkbox"
                checked={isNewCalendar}
                onChange={(e) => setIsNewCalendar(e.target.checked)}
                className="mr-2"
              />
              새 Google 캘린더 생성
            </label>
            {isNewCalendar && (
              <>
                <input
                  type="text"
                  placeholder="새 캘린더 이름"
                  value={newCalendarName}
                  onChange={(e) => setNewCalendarName(e.target.value)}
                  className="w-full mt-2 p-2 border border-gray-300 rounded"
                />
                <div className="flex items-center mt-2">
                  <label className="block text-gray-700 mr-4">
                    색상 선택 <span className="w-6 h-6 rounded-full inline-block mr-2" style={{ backgroundColor: googleCalendarColors[newCalendarColor]?.code }}></span>:
                  </label>
                  <select
                    value={newCalendarColor}
                    onChange={(e) => setNewCalendarColor(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">Google 캘린더 색상을 선택하세요</option>
                    {Object.entries(googleCalendarColors).map(([colorId, colorInfo]) => (
                      <option key={colorId} value={colorId} style={{ color: colorInfo.code }}>
                        {colorInfo.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
          </div>}

          {/* 스케줄 입력 필드 */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">수업 스케줄</label>
            {schedule.map((item, index) => (
              <div key={index} className="flex items-center mb-2">
                <select
                  value={item.dayOfWeek}
                  onChange={(e) => handleScheduleChange(index, "dayOfWeek", e.target.value)}
                  className="mr-2 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">요일 선택</option>
                  <option value="Monday">월요일</option>
                  <option value="Tuesday">화요일</option>
                  <option value="Wednesday">수요일</option>
                  <option value="Thursday">목요일</option>
                  <option value="Friday">금요일</option>
                  <option value="Saturday">토요일</option>
                  <option value="Sunday">일요일</option>
                </select>
                <input
                  type="time"
                  value={item.startTime}
                  onChange={(e) => handleScheduleChange(index, "startTime", e.target.value)}
                  className="mr-2 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                  type="number"
                  value={item.duration}
                  placeholder="분"
                  onChange={(e) => handleScheduleChange(index, "duration", e.target.value)}
                  className="p-2 bor  der border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button type="button" onClick={() => removeScheduleRow(index)} className="ml-2 p-2 bg-red-500 text-white rounded hover:bg-red-600 transition">
                  삭제
                </button>
              </div>
            ))}
            <button type="button" onClick={addScheduleRow} className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
              스케줄 추가
            </button>
          </div>

          <button type="submit" className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600 transition">
            강의 수정
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCourse;
