import axios from "../utils/axiosInstance";
import { getAuthorizationHeaders } from "../utils/authHeaders";

// QnA 목록 조회 (학생/교사에 맞는)
export const getQnaList = async (page, status, search, courseId, studentId) => {
  const { data } = await axios.get("/api/qnas", {
    params: { page, status, search, courseId, studentId },
    headers: getAuthorizationHeaders(),
  });
  return data;
};

// QnA 세부 정보 조회
export const getQnaDetails = async (qnaId) => {
  const { data } = await axios.get(`/api/qnas/${qnaId}`, {
    headers: getAuthorizationHeaders(),
  });
  return data;
};

// QnA 생성
export const postQna = async (formData) => {
  const { data } = await axios.post("/api/qnas", formData, {
    headers: { ...getAuthorizationHeaders(), "Content-Type": "multipart/form-data" },
  });
  return data;
};

// QnA에 댓글 추가
export const postQnaComment = async (qnaId, comment, files) => {
  const formData = new FormData();
  formData.append("comment", comment);
  files.forEach((file) => formData.append("files", file));

  const { data } = await axios.post(`/api/qnas/${qnaId}/answers`, formData, {
    headers: { ...getAuthorizationHeaders(), "Content-Type": "multipart/form-data" },
  });
  return data;
};

// QnA 상태 업데이트 (종료 포함)
export const updateQnaStatus = async (qnaId, status) => {
  const { data } = await axios.put(`/api/qnas/${qnaId}/status`, { status }, { headers: getAuthorizationHeaders() });
  return data;
};

// QnA 본문 파일 다운로드
export const downloadQnaFile = async (qnaId, fileId, filename) => {
  try {
    const response = await axios.get(`/api/qnas/${qnaId}/files/${fileId}`, {
      headers: getAuthorizationHeaders(),
      responseType: "blob", // 파일 다운로드를 위해 blob으로 설정
    });
    const blob = new Blob([response.data], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; // 파일 이름 지정
    document.body.appendChild(a);
    a.click();
  } catch (error) {
    console.error("파일 다운로드 실패:", error);
    alert("파일 다운로드에 실패했습니다.");
  }
};

// QnA 댓글 파일 다운로드
export const downloadQnaCommentFile = async (qnaId, commentId, fileId, filename) => {
  try {
    const response = await axios.get(`/api/qnas/${qnaId}/answers/${commentId}/files/${fileId}`, {
      headers: getAuthorizationHeaders(),
      responseType: "blob", // 파일 다운로드를 위해 blob으로 설정
    });
    const blob = new Blob([response.data], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; // 파일 이름 지정
    document.body.appendChild(a);
    a.click();
  } catch (error) {
    console.error("파일 다운로드 실패:", error);
    alert("파일 다운로드에 실패했습니다.");
  }
};

// QnA 삭제
export const deleteQna = async (qnaId) => {
  const { data } = await axios.delete(`/api/qnas/${qnaId}`, {
    headers: getAuthorizationHeaders(),
  });
  return data;
};
