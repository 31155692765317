import React from "react";
import Modal from "../Modal";

const CreateAssignmentModal = ({ modalOpen, setModalOpen, userRole, newAssignment, setNewAssignment, handleCreateAssignment }) => {
  return (
    <Modal isOpen={modalOpen.createAssignment} onClose={() => setModalOpen((prev) => ({ ...prev, ["createAssignment"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-6">
        <h3 className="text-xl font-semibold">과제 생성</h3>
        <input type="text" placeholder="제목" value={newAssignment.title} onChange={(e) => setNewAssignment({ ...newAssignment, title: e.target.value })} className="border rounded p-2 w-full mb-2" />
        <textarea
          placeholder="설명"
          value={newAssignment.description}
          onChange={(e) => setNewAssignment({ ...newAssignment, description: e.target.value })}
          className="border rounded p-2 w-full mb-2"
        />
        <input type="date" value={newAssignment.dueDate} onChange={(e) => setNewAssignment({ ...newAssignment, dueDate: e.target.value })} className="border rounded p-2 w-full mb-2" />
        <button onClick={handleCreateAssignment} className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition">
          과제 생성
        </button>
        <button onClick={() => setModalOpen((prev) => ({ ...prev, ["createAssignment"]: false }))} className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
          닫기
        </button>
      </div>
    </Modal>
  );
};

export default CreateAssignmentModal;
