// src/components/Folder/FolderModal.js

import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import { getCategories } from "../../services/categoryService";

const FolderModal = ({ isOpen, onClose, onSubmit, selectedFolder, parentFolder }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // 모달 열릴 때마다 초기화
    setName(selectedFolder ? selectedFolder.name : "");
    setDescription(selectedFolder ? selectedFolder.description : "");
    setCategory(selectedFolder && selectedFolder.category ? selectedFolder.category : "");

    // 카테고리 로드
    const loadCategories = async () => {
      const response = await getCategories();
      setCategories(response.data);
    };
    loadCategories();
  }, [isOpen, selectedFolder]);

  const handleSubmit = () => {
    const folderData = { name, description, parentFolder: parentFolder?._id || null, category: parentFolder ? parentFolder.category : category };
    onSubmit(folderData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h3 className="text-xl font-bold mb-4">폴더 생성 / 수정</h3>
      <input type="text" placeholder="폴더명" value={name} onChange={(e) => setName(e.target.value)} className="border rounded px-2 py-1 w-full mb-2" />
      <textarea placeholder="설명" value={description} onChange={(e) => setDescription(e.target.value)} className="border rounded px-2 py-1 w-full mb-2" />

      {!parentFolder && (
        <select value={category} onChange={(e) => setCategory(e.target.value)} className="border rounded px-2 py-1 w-full mb-2">
          <option value="">카테고리 선택</option>
          {categories.map((cat) => (
            <option key={cat._id} value={cat._id}>
              {cat.name}
            </option>
          ))}
        </select>
      )}

      <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">
        저장
      </button>
    </Modal>
  );
};

export default FolderModal;
