import React from "react";
import FileLink from "../../FileLink";

const QnaAttachments = ({ qnaId, files, onDownload }) => (
  <div className="attachments mb-6">
    <h2 className="text-lg font-medium mb-2">첨부 파일:</h2>
    <div className="space-y-1">
      {files.map((file, index) => (
        <FileLink key={file._id} fileId={file._id} onDownload={() => onDownload(qnaId, file._id, file.filename)} label={`첨부파일 ${index + 1}`} />
      ))}
    </div>
  </div>
);

export default QnaAttachments;
