import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import AppRoutes from "./routes/AppRoutes";
import "./styles/tailwind.css";
import "./styles/global.css"; // 경로를 상대적으로 지정
import { AppProvider } from "./contexts/AppContext";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service Worker registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("Service Worker registration failed: ", registrationError);
      });
  });
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot 사용
root.render(
  <AppProvider>
    <Router>
      <AppRoutes />
    </Router>
  </AppProvider>
);
