import axios from "../utils/axiosInstance"; // axiosInstance를 사용

// 제안 목록 조회 서비스
export const fetchProposals = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get("/api/proposals", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response.data);

    return response.data; // 성공 시 데이터를 반환
  } catch (error) {
    console.error("제안 목록을 불러오는 중 오류 발생:", error);
    throw error; // 에러를 호출한 쪽에서 처리할 수 있도록 throw
  }
};

// 제안 생성 서비스
export const createProposal = async (proposalData) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post("/api/proposals", proposalData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // 성공 시 데이터를 반환
  } catch (error) {
    console.error("제안 생성 실패:", error.response.data.message);
    throw error; // 에러를 호출한 쪽에서 처리할 수 있도록 throw
  }
};

// 제안 상태 업데이트 서비스
export const updateProposalStatus = async (proposalId, status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `/api/proposals/${proposalId}`,
      { status }, // 상태 업데이트
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("제안 상태 업데이트 실패:", error);
    throw error;
  }
};
