import React from "react";
import { FaFileDownload } from "react-icons/fa";

const FileLink = ({ fileId, onDownload, label }) => (
  <a
    href="#!"
    onClick={(e) => {
      e.preventDefault();
      onDownload(fileId);
    }}
    className="text-blue-600 underline hover:text-blue-800 transition-colors"
  >
    <FaFileDownload className="inline-block mr-1" />
    {label}
  </a>
);

export default FileLink;
