import React, { useEffect, useState, useCallback, useMemo } from "react";
import { uploadMaterial, deleteMaterial, fetchMaterials, fetchMaterialContent, fetchMaterialsByCourse } from "../../services/materialService";
import { fetchCourses } from "../../services/courseService";
import Pagination from "../../components/Pagination";
import MaterialListItem from "../../components/Material/MaterialListItem";
import MaterialViewerModal from "../../components/Material/MaterialViewerModal";
import UploadMaterialModal from "../../components/Material/UploadMaterialModal";

const MaterialLibrary = () => {
  const userRole = useMemo(() => localStorage.getItem("role"), []);
  const [materials, setMaterials] = useState([]);
  const [courses, setCourses] = useState([]);
  const [pagination, setPagination] = useState({
    materialPage: 1,
    totalPages: 1,
  });
  const [filterTerms, setFilterTerms] = useState({
    selectedCourse: "",
    materialSearch: "",
    materialCategory: "",
  });
  const [modalOpen, setModalOpen] = useState({
    material: false,
    uploadMaterial: false,
  });
  const [selected, setSelected] = useState({ material: null });

  useEffect(() => {
    const fetchInitialData = async () => {
      const [coursesData] = await Promise.all([fetchCourses()]);
      setCourses(coursesData);
      loadMaterials();
    };
    fetchInitialData();
  }, []);

  const handleMaterialView = useCallback(async (material) => {
    console.log(material);
    const content = await fetchMaterialContent(material._id);
    setSelected((prev) => ({ ...prev, material: { ...material, content } }));
    handleOpenModal("material");
  }, []);

  const loadMaterials = useCallback(async () => {
    const { materialPage } = pagination;
    const { selectedCourse, materialSearch, materialCategory } = filterTerms;
    if (filterTerms.selectedCourse !== "") {
      const { data } = await fetchMaterialsByCourse(selectedCourse, materialPage, materialSearch, materialCategory, 10);
      setMaterials(data.materials);
      setPagination((prev) => ({ ...prev, totalPages: data.totalPages }));
    } else {
      const { data } = await fetchMaterials(materialPage, materialSearch, materialCategory);
      setMaterials(data.materials);
      setPagination((prev) => ({ ...prev, totalPages: data.totalPages }));
    }
  }, [pagination.materialPage, filterTerms]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, materialPage: 1 }));
  }, [filterTerms]);

  useEffect(() => {
    loadMaterials();
  }, [pagination.materialPage, filterTerms, loadMaterials]);

  const handleFilterChange = (field, value) => {
    setFilterTerms((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePageChange = (page) => setPagination((prev) => ({ ...prev, materialPage: page }));

  const handleOpenModal = (type, data = null) => setModalOpen((prev) => ({ ...prev, [type]: true }));

  const handleDeleteMaterial = useCallback(async (material) => {
    // console.log(materials);
    const content = await deleteMaterial(material._id);
    // console.log(material._id);
    // console.log(materials);
    // setMaterials(materials.filter((m) => m._id != material._id));
  }, []);

  const handleMaterialUpload = async (materialData, file) => {
    if (!file) return alert("파일을  선택하세요.");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", materialData.fileName);
    formData.append("courseIds", JSON.stringify(materialData.courseIds));
    formData.append("category", materialData.category);
    formData.append("problemId", materialData.problemId);
    formData.append("isPublic", materialData.isPublic);
    await uploadMaterial(formData);
    alert("파일이 성공적으로 업로드되었습니다.");
    // setModalOpen((prev) => ({ ...prev, ["uploadMaterial"]: false }));
    loadMaterials();
  };

  return (
    <div className="min-h-screen bg-gray-50 py-10">
      <div className="max-w-7xl mx-auto bg-white rounded-xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">자료실</h1>

        {userRole === "teacher" && (
          <div className="flex justify-end mb-6">
            <button
              onClick={() => setModalOpen((prev) => ({ ...prev, ["uploadMaterial"]: true }))}
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded-lg shadow-md transition"
            >
              자료 등록
            </button>
          </div>
        )}

        <div className="flex space-x-4 mb-6">
          <select value={filterTerms.selectedCourse} onChange={(e) => handleFilterChange("selectedCourse", e.target.value)} className="border border-gray-300 rounded-lg p-3 shadow-md w-1/3">
            <option value="">전체 과목</option>
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.courseName}
              </option>
            ))}
          </select>

          <select value={filterTerms.materialCategory} onChange={(e) => handleFilterChange("materialCategory", e.target.value)} className="border border-gray-300 rounded-lg p-3 shadow-md w-1/3">
            <option value="">카테고리 선택</option>
            <option value="Lecture">강의</option>
            <option value="Problem">문제</option>
            {/* <option value="Solution">풀이</option> */}
            <option value="Project">프로젝트</option>
            <option value="Reference">참고자료</option>
            <option value="Practice">연습자료</option>
            <option value="Exam Prep">시험 준비</option>
            <option value="Study Plan">학습 계획</option>
          </select>

          <input
            type="text"
            placeholder="파일명 검색"
            value={filterTerms.materialSearch}
            onChange={(e) => handleFilterChange("materialSearch", e.target.value)}
            className="flex-grow border border-gray-300 rounded-lg p-3 shadow-md"
          />
        </div>

        <ul className="space-y-4">
          {materials.length ? (
            materials.map((material) => <MaterialListItem key={material._id} item={material} handleMaterialView={handleMaterialView} handleDeleteMaterial={handleDeleteMaterial} userRole={userRole} />)
          ) : (
            <p className="text-center text-gray-500">등록된 자료가 없습니다.</p>
          )}
        </ul>

        {pagination.totalPages > 1 && <Pagination currentPage={pagination.materialPage} totalPages={pagination.totalPages} onPageChange={handlePageChange} />}
      </div>

      {modalOpen.material && selected.material && <MaterialViewerModal modalOpen={modalOpen} setModalOpen={setModalOpen} selected={selected} />}
      {modalOpen.uploadMaterial && <UploadMaterialModal modalOpen={modalOpen} onUpload={handleMaterialUpload} setModalOpen={setModalOpen} courseOptions={courses} />}
    </div>
  );
};

export default MaterialLibrary;
