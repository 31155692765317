import React from "react";
import { useWebSocket } from "../contexts/WebSocketProvider";
import { useNavigate } from "react-router-dom";

const ActiveUsersList = ({ isVisible }) => {
  const { userCount, userList } = useWebSocket();
  const navigate = useNavigate();

  if (!isVisible) return null;

  const handleViewLogs = () => {
    navigate("/connection-logs"); // 접속 기록 페이지로 이동
  };

  return (
    <div className="fixed bottom-4 right-4 bg-white shadow-lg rounded-lg p-4 w-64">
      <h3 className="text-lg font-semibold mb-2">접속 중인 사용자 ({userCount})</h3>
      <ul className="overflow-y-auto max-h-32">
        {userList.map((user, index) => (
          <li key={index} className="text-gray-700 text-sm">
            {user}
          </li>
        ))}
      </ul>
      <button onClick={handleViewLogs} className="w-full text-center bg-blue-500 text-white font-medium py-1 rounded hover:bg-blue-600 transition">
        접속 기록 보기
      </button>
    </div>
  );
};

export default ActiveUsersList;
