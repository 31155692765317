import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
    // 에러 발생 시 상태 업데이트
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, info) {
    // 에러를 로깅하거나 외부 서비스에 전송 가능
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // 에러가 발생했을 때 UI에서 표시할 내용
      return <h2>Error: {this.state.errorMessage}</h2>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
