import React from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-indigo-50 to-indigo-200">
      <div className="bg-white p-12 rounded-2xl shadow-2xl text-center w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 transform transition duration-300 hover:scale-105">
        {/* 로고 섹션 */}
        <div className="flex justify-center mb-6">
          <img src="/images/logo.png" alt="The C0d1ng LMS Logo" className="h-20" />
        </div>

        <p className="text-lg text-gray-700 mb-8 leading-relaxed">
          <span className="font-semibold text-indigo-500">The C0d1ng LMS</span>에 오신 것을 환영합니다.
          <br />
          과외 수업을 체계적으로 관리하고, 원활한 소통으로 최고의 학습 경험을 누려보세요.
        </p>

        <div className="flex space-x-4 justify-center mt-8">
          <Link to="/login">
            <button className="px-8 py-3 bg-indigo-500 text-white rounded-full hover:bg-indigo-600 transition duration-300 shadow-lg">로그인</button>
          </Link>
          <Link to="/signup">
            <button className="px-8 py-3 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300 shadow-lg">회원가입</button>
          </Link>
        </div>
      </div>

      <footer className="w-full py-4 bg-gray-800 text-white text-center mt-10 shadow-inner">
        <p className="text-sm">
          &copy; 2024 The C0d1ng LMS. <span className="font-semibold">All Rights Reserved.</span>
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;
