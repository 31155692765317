import axios from "../utils/axiosInstance";
import { getAuthorizationHeaders } from "../utils/authHeaders";

// 일정 목록 조회
export const fetchSchedules = async (courseId) => {
  const response = await axios.get(`/api/schedules/${courseId}`, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

// 수업 생성
export const createClassSession = async ({ courseId, scheduleId, notes }) => {
  const response = await axios.post(
    `/api/classes`,
    { courseId, scheduleId, notes },
    {
      headers: getAuthorizationHeaders(),
    }
  );
  return response.data;
};

// 특정 강의의 다가오는 수업 목록 조회
export const fetchUpcomingClasses = async (courseId, page) => {
  const response = await axios.get(`/api/classes/${courseId}/upcoming?page=${page}&limit=5`, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

// 특정 강의의 지난 수업 목록 조회
export const fetchPastClasses = async (courseId, page, search, startDate, endDate) => {
  console.log(courseId, page, search, startDate, endDate);
  const response = await axios.get(`/api/classes/${courseId}/past?page=${page}&search=${search || ""}&limit=5&startDate=${startDate || ""}&endDate=${endDate || ""}`, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

// 수업 노트 업데이트
export const updateClassNotes = async (classId, notesData) => {
  const response = await axios.post(`/api/classes/${classId}/notes`, notesData, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

// 과제 목록 조회
export const fetchAssignments = async (courseId) => {
  try {
    const response = await axios.get(`/api/assignments/${courseId}`, {
      headers: getAuthorizationHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("과제 목록 조회 실패:", error);
    throw error;
  }
};

// 과제 제출
export const submitAssignment = async (assignmentId, submittedFile) => {
  const formData = new FormData();
  formData.append("submittedFile", submittedFile);

  try {
    const response = await axios.put(`/api/assignments/submit/${assignmentId}`, formData, {
      headers: {
        ...getAuthorizationHeaders(),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("과제 제출 실패:", error);
    throw error;
  }
};

// 과제 파일 다운로드
export const downloadAssignment = async (assignmentId) => {
  try {
    const response = await axios.get(`/api/assignments/download/${assignmentId}`, {
      responseType: "blob",
      headers: getAuthorizationHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("파일 다운로드 실패:", error);
    alert("파일 다운로드에 실패했습니다.");
  }
};

// 과제 생성
export const createAssignment = async (assignmentData) => {
  const response = await axios.post("/api/assignments/", assignmentData, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

// 수업 댓글 추가
export const addClassComment = async (classId, content, file) => {
  const formData = new FormData();
  formData.append("content", content);
  if (file) formData.append("file", file);

  const response = await axios.post(`/api/classes/${classId}/comments`, formData, {
    headers: {
      ...getAuthorizationHeaders(),
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

// 수업 댓글 목록 조회
export const fetchClassComments = async (classId) => {
  const response = await axios.get(`/api/classes/${classId}/comments`, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

// 댓글 파일 다운로드
export const downloadCommentFile = async (classId, commentId) => {
  try {
    const response = await axios.get(`/api/classes/${classId}/comments/${commentId}/download`, {
      headers: getAuthorizationHeaders(),
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("파일 다운로드 실패:", error);
    alert("파일 다운로드에 실패했습니다.");
  }
};

// 수업 댓글 삭제
export const deleteClassComment = async (classId, commentId) => {
  try {
    const response = await axios.delete(`/api/classes/${classId}/comments/${commentId}`, {
      headers: getAuthorizationHeaders(),
    });
    return response.data.comments;
  } catch (error) {
    console.error("댓글 삭제 실패:", error);
    throw error;
  }
};
