import React, { useState, useEffect } from "react";
import { getTopLevelFolders, getFolderContents, createFolder, updateFolder, deleteFolder, addContentToFolder } from "../../services/folderService";
import { getCategories, createCategory, updateCategory, deleteCategory } from "../../services/categoryService";
import FolderTree from "../../components/Folder/FolderTree";
import FolderModal from "../../components/Folder/FolderModal";
import ContentModal from "../../components/Folder/ContentModal";
import CategoryModal from "../../components/Category/CategoryModal";

const FolderManagementPage = () => {
  const [folders, setFolders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [parentFolder, setParentFolder] = useState(null); // 상위 폴더 상태 추가

  useEffect(() => {
    loadTopLevelFolders();
    loadCategories();
  }, []);

  const loadTopLevelFolders = async () => {
    const response = await getTopLevelFolders();
    setFolders(response.data);
  };

  const loadCategories = async () => {
    const response = await getCategories();
    setCategories(response.data);
  };

  const handleFolderSelect = async (folderId) => {
    const { data } = await getFolderContents(folderId);
    setSelectedFolder(data);
  };

  const handleCreateFolder = async (newFolderData) => {
    await createFolder({ ...newFolderData, parentFolder }); // 상위 폴더 지정
    loadTopLevelFolders();
    setIsFolderModalOpen(false);
    setParentFolder(null); // 상위 폴더 초기화
  };

  const handleUpdateFolder = async (folderId, updatedData) => {
    await updateFolder(folderId, updatedData);
    loadTopLevelFolders();
  };

  const handleDeleteFolder = async (folderId) => {
    await deleteFolder(folderId);
    loadTopLevelFolders();
  };

  const handleAddContent = async (contentData) => {
    await addContentToFolder(selectedFolder?._id, contentData); // 선택된 폴더가 있을 때만 추가
    handleFolderSelect(selectedFolder?._id);
    setIsContentModalOpen(false);
  };

  const handleCreateCategory = async (categoryData) => {
    await createCategory(categoryData);
    loadCategories();
    setIsCategoryModalOpen(false);
  };

  const handleUpdateCategory = async (categoryId, updatedData) => {
    await updateCategory(categoryId, updatedData);
    loadCategories();
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      loadCategories();
    } catch (error) {
      alert("해당 카테고리를 사용하는 폴더가 있어 삭제할 수 없습니다.");
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">폴더 및 카테고리 관리</h1>

      {/* 카테고리 관리 영역 */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold">카테고리 목록</h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
          onClick={() => {
            setSelectedCategory(null);
            setIsCategoryModalOpen(true);
          }}
        >
          새 카테고리 추가
        </button>
        <ul className="mt-4 list-disc pl-6">
          {categories.map((category) => (
            <li key={category._id} className="flex justify-between items-center">
              <span>{category.name}</span>
              <div>
                <button
                  onClick={() => {
                    setSelectedCategory(category);
                    setIsCategoryModalOpen(true);
                  }}
                  className="bg-gray-500 text-white px-2 py-1 rounded mr-2"
                >
                  수정
                </button>
                <button onClick={() => handleDeleteCategory(category._id)} className="bg-red-500 text-white px-2 py-1 rounded">
                  삭제
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 폴더 관리 영역 */}
      <div className="flex">
        <FolderTree folders={folders} onSelectFolder={handleFolderSelect} />
        <div className="flex-grow ml-4">
          {selectedFolder ? (
            <>
              <h2 className="text-xl font-semibold mb-2">{selectedFolder.folderName}</h2>
              <button className="bg-blue-500 text-white px-4 py-2 rounded mb-2" onClick={() => setIsContentModalOpen(true)}>
                자료 및 강의 추가
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mb-2 ml-2"
                onClick={() => {
                  setParentFolder(selectedFolder._id); // 선택한 폴더가 상위 폴더로 설정됨
                  setIsFolderModalOpen(true);
                }}
              >
                하위 폴더 추가
              </button>
              <button className="bg-gray-500 text-white px-4 py-2 rounded mb-2 ml-2" onClick={() => setIsFolderModalOpen(true)}>
                폴더 수정
              </button>
              <button className="bg-red-500 text-white px-4 py-2 rounded mb-2 ml-2" onClick={() => handleDeleteFolder(selectedFolder._id)}>
                폴더 삭제
              </button>

              <div className="mt-4">
                <h3 className="text-lg font-semibold">강의 목록</h3>
                <ul className="list-disc pl-6">
                  {(selectedFolder?.courses ?? []).map((course) => (
                    <li key={course._id}>{course.courseName}</li>
                  ))}
                </ul>
                <h3 className="text-lg font-semibold mt-4">자료 목록</h3>
                <ul className="list-disc pl-6">
                  {(selectedFolder?.materials ?? []).map((material) => (
                    <li key={material._id}>
                      {material.fileName} - {material.category}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <>
              <p>폴더를 선택하세요.</p>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mb-2"
                onClick={() => {
                  setParentFolder(null); // 최상위 폴더 추가를 위해 null 설정
                  setIsFolderModalOpen(true);
                }}
              >
                최상위 폴더 추가
              </button>
            </>
          )}
        </div>
      </div>

      <FolderModal
        isOpen={isFolderModalOpen}
        onClose={() => {
          setIsFolderModalOpen(false);
          setParentFolder(null); // 폴더 생성 완료 후 초기화
        }}
        onSubmit={handleCreateFolder}
        selectedFolder={selectedFolder}
      />
      <ContentModal isOpen={isContentModalOpen} onClose={() => setIsContentModalOpen(false)} onSubmit={handleAddContent} />
      <CategoryModal
        isOpen={isCategoryModalOpen}
        onClose={() => setIsCategoryModalOpen(false)}
        onSubmit={selectedCategory ? (data) => handleUpdateCategory(selectedCategory._id, data) : handleCreateCategory}
        selectedCategory={selectedCategory}
      />
    </div>
  );
};

export default FolderManagementPage;
