import React, { useState, useEffect } from "react";
import { addCommentToAssignment, fetchAssignmentComments, downloadCommentFile, deleteCommentFromAssignment } from "../../services/assignmentService";

const AssignmentComments = ({ assignmentId, currentUser }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // 파일 상태

  useEffect(() => {
    const loadComments = async () => {
      try {
        const fetchedComments = await fetchAssignmentComments(assignmentId);
        setComments(fetchedComments);
      } catch (error) {
        console.error("댓글 불러오기 실패:", error);
      }
    };

    loadComments();
  }, [assignmentId]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // 선택된 파일 저장
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;

    try {
      const updatedComments = await addCommentToAssignment(assignmentId, newComment, selectedFile);
      setComments(updatedComments); // 댓글 리스트 업데이트
      setNewComment(""); // 입력 필드 초기화
      setSelectedFile(null); // 파일 상태 초기화
    } catch (error) {
      console.error("댓글 추가 실패:", error);
    }
  };

  const handleFileDownload = async (commentId, file) => {
    try {
      const data = await downloadCommentFile(assignmentId, commentId); // 파일 다운로드 API 호출
      const blob = new Blob([data], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file || "comment"; // 파일 이름 지정
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("파일 다운로드 실패:", error);
      alert("파일 다운로드에 실패했습니다.");
    }
  };

  const handleCommentDelete = async (commentId) => {
    try {
      const updatedComments = await deleteCommentFromAssignment(assignmentId, commentId);
      setComments(updatedComments); // 댓글 리스트 업데이트
    } catch (error) {
      alert(error.response.data.message);
      console.error("댓글 삭제 실패:", error);
    }
  };

  // 댓글 작성 후 3분이 지나면 삭제 버튼을 숨김
  const canDeleteComment = (createdAt) => {
    const currentTime = Date.now();
    const commentCreatedTime = new Date(createdAt).getTime();
    const timeDiffInMinutes = (currentTime - commentCreatedTime) / (1000 * 60); // 밀리초를 분으로 변환
    return timeDiffInMinutes <= 3; // 3분 이내인지 확인
  };

  return (
    <div className="mt-6 max-h-[800px] flex flex-col bg-gray-100 rounded-lg shadow-lg overflow-hidden border border-gray-200">
      {/* 댓글 입력 부분 */}
      <div className="bg-white p-4 shadow-md">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="댓글을 입력하세요"
          className="w-full border rounded p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition"
          rows={3}
        />
        <input type="file" onChange={handleFileChange} className="mt-2 block w-full text-gray-700" />
        <button onClick={handleCommentSubmit} className="mt-3 w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition">
          댓글 달기
        </button>
      </div>

      {/* 댓글 목록 */}
      <div className="overflow-y-auto p-4 flex-1 bg-gray-50">
        <ul className="space-y-4">
          {comments.map((comment) => (
            <li key={comment._id} className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold text-gray-800">{comment.user.name}</p>
                {canDeleteComment(comment.createdAt) && (
                  <button onClick={() => handleCommentDelete(comment._id)} className="rounded-full hover:bg-red-100 p-1 transition duration-300 ease-in-out">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5 text-red-500">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
              </div>
              <p className="text-gray-700 mt-1 whitespace-pre-line text-start">{comment.content}</p>
              {comment.file && (
                <div className="mt-2 text-start">
                  <button onClick={() => handleFileDownload(comment._id, comment.file)} className="text-blue-500 underline hover:text-blue-700">
                    첨부파일 다운로드
                  </button>
                </div>
              )}
              <p className="text-xs text-gray-500 mt-2 text-start">{new Date(comment.createdAt).toLocaleString()}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AssignmentComments;
