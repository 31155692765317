import axios from "../utils/axiosInstance"; // axiosInstance 설정이 되어 있어야 함
import { getAuthorizationHeaders } from "../utils/authHeaders";

// 폴더 관련 API
export const getTopLevelFolders = () =>
  axios.get("/api/folders/top", {
    headers: getAuthorizationHeaders(),
  });

export const getFolderContents = (folderId) =>
  axios.get(`/api/folders/${folderId}`, {
    headers: getAuthorizationHeaders(),
  });

export const createFolder = (folderData) =>
  axios.post("/api/folders", folderData, {
    headers: getAuthorizationHeaders(),
  });

export const updateFolder = (folderId, folderData) =>
  axios.put(`/api/folders/${folderId}`, folderData, {
    headers: getAuthorizationHeaders(),
  });

export const deleteFolder = (folderId) =>
  axios.delete(`/api/folders/${folderId}`, {
    headers: getAuthorizationHeaders(),
  });

// 폴더에 콘텐츠 추가
export const addContentToFolder = (folderId, contentData) =>
  axios.put(`/api/folders/${folderId}/add-content`, contentData, {
    headers: getAuthorizationHeaders(),
  });
