import axios from "../utils/axiosInstance";

// 특정 강의의 다가오는 수업 목록 조회
export const fetchOngoingAssignmentsByCourseId = async (courseId, page) => {
  const token = localStorage.getItem("token");

  const response = await axios.get(`/api/assignments/ongoing/${courseId}?page=${page}&limit=5`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// 특정 강의의 지난 수업 목록 조회
export const fetchPastAssignmentsByCourseId = async (courseId, page, search, start, end) => {
  const token = localStorage.getItem("token");
  console.log(courseId, page, search, start, end);
  const response = await axios.get(`/api/assignments/past/${courseId}?page=${page}&search=${search || ""}&limit=5&startDate=${start || ""}&endDate=${end || ""}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// 댓글 추가
export const addCommentToAssignment = async (assignmentId, comment, submittedFile) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("content", comment);
  formData.append("file", submittedFile); // 파일 추가

  const response = await axios.post(`/api/assignments/${assignmentId}/comments`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// 댓글 목록 조회
export const fetchAssignmentComments = async (assignmentId) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`/api/assignments/${assignmentId}/comments`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// 댓글에 첨부된 파일 다운로드
export const downloadCommentFile = async (assignmentId, commentId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`/api/assignments/${assignmentId}/comments/${commentId}/download`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob", // 파일을 다운로드하기 위한 설정
    });
    return response.data;
  } catch (error) {
    console.error("파일 다운로드 실패:", error);
    alert("파일 다운로드에 실패했습니다.");
  }
};

// 댓글 삭제
export const deleteCommentFromAssignment = async (assignmentId, commentId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(`/api/assignments/${assignmentId}/comments/${commentId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.comments; // 업데이트된 댓글 리스트 반환
  } catch (error) {
    console.error("댓글 삭제 실패:", error);
    throw error;
  }
};
