// src/services/teacherService.js

import axios from "../utils/axiosInstance";

export const fetchUpcomingClasses = async () => {
  const { data } = await axios.get("/api/teacher/upcoming-classes", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  console.log(data);
  return data;
};

export const fetchProposals = async () => {
  const { data } = await axios.get("/api/teacher/proposals", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  console.log(data);
  return data;
};

export const fetchStudents = async () => {
  const { data } = await axios.get("/api/teacher/students", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};

export const fetchOngoingCourses = async () => {
  const { data } = await axios.get("/api/teacher/ongoing-courses", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};

export const fetchQnas = async () => {
  const { data } = await axios.get("/api/teacher/qnas", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};
