import React from "react";

const StudentSelector = ({ studentList, selectedStudent, setSelectedStudent }) => {
  const handleStudentChange = (e) => {
    const studentId = e.target.value;
    const selected = studentList.find((student) => student._id === studentId);
    setSelectedStudent(selected);
  };

  return (
    <div className="mb-6">
      <label className="block text-gray-700 font-medium mb-2">학생 선택</label>
      <select
        value={selectedStudent?._id ?? ""}
        onChange={handleStudentChange}
        className="border border-gray-300 rounded-lg p-3 w-full bg-white focus:ring-2 focus:ring-blue-500 focus:outline-none transition"
      >
        <option value="">학생을 선택하세요</option>
        {studentList.map((student) => (
          <option key={student._id} value={student._id}>
            {student.user.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StudentSelector;
