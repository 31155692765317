import React from "react";
import { format } from "date-fns";

const QnaHeader = ({ qnaData }) => (
  <div className="mb-6">
    <h1 className="text-3xl font-bold text-gray-900">{qnaData.question}</h1>
    <p className="text-sm text-gray-500">작성일: {format(new Date(qnaData.createdAt), "yyyy-MM-dd HH:mm")}</p>
    <p className={`text-sm font-semibold mt-2 ${qnaData.status === "ongoing" ? "text-green-500" : "text-red-500"}`}>상태: {qnaData.status === "ongoing" ? "진행 중" : "완료"}</p>
  </div>
);

export default QnaHeader;
