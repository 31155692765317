import axios from "../utils/axiosInstance";

// 강의 목록을 가져오는 함수
export const fetchCourses = async () => {
  const token = localStorage.getItem("token"); // 토큰 가져오기

  try {
    const response = await axios.get("/api/courses", {
      // API 경로에서 /api 생략
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // 강의 데이터를 반환
  } catch (error) {
    console.error("강의 목록을 불러오는 중 오류 발생:", error);
    throw error; // 에러를 호출하는 쪽에서 처리할 수 있도록 throw
  }
};

// 강의 목록을 가져오는 함수
export const fetchCourseById = async (courseId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`/api/courses/${courseId}`, {
      // API 경로에서 /api 생략
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // 강의 데이터를 반환
  } catch (error) {
    console.error("강의 목록을 불러오는 중 오류 발생:", error);
    throw error; // 에러를 호출하는 쪽에서 처리할 수 있도록 throw
  }
};

// 강의 생성 함수
export const createCourse = async (courseData) => {
  const token = localStorage.getItem("token");
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.post("/api/courses", courseData, {
      // API 경로에서 /api 생략
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Google-Access-Token": accessToken,
      },
    });
    return response.data; // 생성된 강의 반환
  } catch (error) {
    console.error("강의 생성 중 오류 발생:", error);
    throw error; // 에러를 호출하는 쪽에서 처리할 수 있도록 throw
  }
};

// 강의 업데이트 함수
export const updateCourse = async (courseId, courseData) => {
  const token = localStorage.getItem("token");
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.put(`/api/courses/${courseId}`, courseData, {
      // API 경로에서 /api 생략
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Google-Access-Token": accessToken,
      },
    });
    return response.data; // 업데이트된 강의 반환
  } catch (error) {
    console.error("강의 업데이트 중 오류 발생:", error);
    throw error; // 에러를 호출하는 쪽에서 처리할 수 있도록 throw
  }
};

// 특정 강의의 수업 목록을 가져오는 함수
export const fetchCourseSessions = async (courseId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`/api/courses/${courseId}/sessions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // 수업 데이터를 반환
  } catch (error) {
    console.error("수업 목록을 불러오는 중 오류 발생:", error);
    throw error; // 에러를 호출하는 쪽에서 처리할 수 있도록 throw
  }
};

const GOOGLE_API_BASE_URL = "https://www.googleapis.com/calendar/v3";

// Google 캘린더 목록 가져오기
export const fetchGoogleCalendars = async () => {
  const token = localStorage.getItem("token");
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${GOOGLE_API_BASE_URL}/users/me/calendarList`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Google-Access-Token": accessToken,
      },
    });
    return response.data.items;
  } catch (error) {
    console.error("Google 캘린더 목록 불러오기 오류:", error);
    throw error;
  }
};

// Google 캘린더 새로 생성하기
export const createGoogleCalendar = async (name, color) => {
  const token = localStorage.getItem("token");
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${GOOGLE_API_BASE_URL}/calendars`,
      {
        summary: name,
        colorId: color,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Google-Access-Token": accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Google 캘린더 생성 오류:", error);
    throw error;
  }
};
