import React from "react";

const AssignmentListItem = ({ item, setSelected, setModalOpen }) => {
  if (!item) {
    return <></>;
  }
  const handleViewComment = () => {
    setSelected((prev) => ({ ...prev, ["assignment"]: item }));
    setModalOpen((prev) => ({ ...prev, ["commentAssignment"]: true }));
  };

  const handleViewDetail = () => {
    setSelected((prev) => ({ ...prev, ["assignment"]: item }));
    setModalOpen((prev) => ({ ...prev, ["assignment"]: true }));
  };

  return (
    <li className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300 ease-in-out border border-gray-200">
      <div className="flex justify-between items-start">
        <div className="space-y-2 text-left">
          <p className="text-lg font-medium text-gray-900">
            <span className="font-semibold text-gray-800">과제명:</span> {item.title}
          </p>
          <p className="text-gray-700">
            <span className="font-semibold text-gray-800">마감 날짜:</span> {new Date(item.dueDate).toLocaleDateString()}
          </p>
        </div>
        <div className="flex flex-col items-end space-y-3">
          <button
            onClick={handleViewComment}
            className="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-2 px-4 rounded-lg font-medium shadow-sm hover:from-blue-600 hover:to-blue-800 transition-colors duration-200 ease-in-out"
          >
            댓글 보기
          </button>
          <button
            onClick={handleViewDetail}
            className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-2 px-4 rounded-lg font-medium shadow-sm hover:from-purple-600 hover:to-indigo-600 transition-colors duration-200 ease-in-out"
          >
            상세 보기
          </button>
        </div>
      </div>
    </li>
  );
};

export default AssignmentListItem;
