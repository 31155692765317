// 유틸리티 함수: 요일을 반환하는 함수
export const getDayName = (dayIndex) => {
  const days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
  return days[dayIndex];
};

export const getHourMinute = (minutes) => {
  let hour = parseInt(minutes / 60);
  let minute = minutes % 60;
  let str = "";
  if (hour) {
    str += hour + "시간";
  }
  if (minute) {
    if (hour) {
      str += " ";
    }
    str += minute + "분";
  }
  if (!hour) {
    str = "0시간";
  }
  return str;
};

export const calculateMinuteDifference = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // 두 시간의 차이를 밀리초로 구함
  const differenceInMilliseconds = endDate - startDate;

  // 밀리초를 분 단위로 변환 (1분 = 60,000밀리초)
  const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);

  return differenceInMinutes;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours()}시 ${String(date.getMinutes()).padStart(2, "0")}분`;
};

const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
export const formatDateWithDay = (dateString) => {
  const date = new Date(dateString);
  const dayOfWeek = daysOfWeek[date.getDay()]; // 요일을 숫자로 가져와서 배열로 변환
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 (${dayOfWeek}) ${date.getHours()}시 ${String(date.getMinutes()).padStart(2, "0")}분`;
};
