import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { getLogList } from "../../services/connectionLogsService";
import { fetchUsers } from "../../services/userService";
import Pagination from "../../components/Pagination";

const ConnectionLogList = () => {
  const [logs, setLogs] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10; // 한 페이지에 보여줄 기록 수

  const fetchLogs = async () => {
    try {
      const data = await getLogList(statusFilter, userFilter, currentPage, limit);
      setLogs(data.logs);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("접속 기록을 불러오는 중 오류가 발생했습니다.", error);
    }
  };

  const fetchUsers2 = async () => {
    try {
      const data = await fetchUsers();
      setUsers(data.users);
    } catch (error) {
      console.error("유저 목록을 불러오는 중 오류가 발생했습니다.", error);
    }
  };

  useEffect(() => {
    fetchLogs();
    fetchUsers2();
  }, [statusFilter, userFilter, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-semibold mb-6">접속 기록</h1>

      {/* 필터링 옵션 */}
      <div className="flex items-center gap-4 mb-6">
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="border p-2 rounded">
          <option value="">전체</option>
          <option value="connected">접속</option>
          <option value="disconnected">끊김</option>
        </select>

        <select value={userFilter} onChange={(e) => setUserFilter(e.target.value)} className="border p-2 rounded">
          <option value="">모든 유저</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.name}
            </option>
          ))}
        </select>
      </div>

      {/* 접속 기록 테이블 */}
      <table className="w-full text-left border">
        <thead>
          <tr>
            <th className="border p-2">일시</th>
            <th className="border p-2">이름</th>
            <th className="border p-2">상태</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log._id}>
              <td className="border p-2">{format(new Date(log.createdAt), "yyyy-MM-dd HH:mm:ss")}</td>
              <td className="border p-2">{log.user?.name || "Unknown"}</td>
              <td className="border p-2">{log.event === "connected" ? "접속" : "끊김"}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 페이지네이션 */}
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default ConnectionLogList;
