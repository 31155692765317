import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createProposal } from "../../services/proposalService"; // 서비스에서 제안 생성 로직 가져오기
import { fetchCourses } from "../../services/courseService"; // 강의 목록 불러오기

const CreateProposal = () => {
  const [courseId, setCourseId] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const courseData = await fetchCourses(); // 강의 목록 불러오기
        setCourses(courseData);
      } catch (error) {
        console.error("강의 목록 불러오기 실패:", error);
      }
    };

    loadCourses();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const proposalData = {
      courseId,
      studentEmail, // 학생 이메일을 백엔드로 전송
    };

    try {
      await createProposal(proposalData); // 서비스 호출
      alert("제안이 성공적으로 생성되었습니다!");
      navigate("/dashboard"); // 제안 생성 후 강사 대시보드로 이동
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg mt-10 w-full max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6 text-center">제안 생성</h2>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">강의 선택:</label>
            <select value={courseId} onChange={(e) => setCourseId(e.target.value)} required className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="">강의를 선택하세요</option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>
                  {course.courseName}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">학생 이메일:</label>
            <input
              type="email"
              value={studentEmail}
              onChange={(e) => setStudentEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <button type="submit" className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600 transition">
            제안 생성
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateProposal;
