import React, { useState, useEffect } from "react";
import { getQnaList, deleteQna } from "../../services/qnaService";
import { fetchCourses } from "../../services/courseService";
import { Link, useNavigate } from "react-router-dom";
import QnaItem from "../../components/Qna/List/QnaItem";
import FilterSection from "../../components/Qna/List/FilterSection";
import Pagination from "../../components/Pagination";
import { useAppContext } from "../../contexts/AppContext";

const QnaList = () => {
  const [qnaList, setQnaList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("ongoing");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { setPageFunction } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const courses = await fetchCourses();
        setCourseList(courses);
      } catch (error) {
        console.error("Failed to fetch course list:", error);
      }
    };
    loadCourses();
  }, []);

  const loadQnaList = async () => {
    try {
      const response = await getQnaList(page, status, searchQuery, selectedCourse?._id ?? "", selectedStudent?._id ?? "");
      setQnaList(response.qnaList);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch QnA list:", error);
    }
  };

  useEffect(() => {
    loadQnaList();
    setPageFunction("/qnas", (data) => loadQnaList());
  }, [page, status, searchQuery, selectedCourse, selectedStudent, setPageFunction]);

  const handleCreateQna = () => {
    navigate("/qna/write");
  };

  const handleDeleteQna = async (qnaId) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      try {
        await deleteQna(qnaId);
        loadQnaList();
      } catch (error) {
        console.error("Failed to delete QnA:", error);
      }
    }
  };

  return (
    <div className="qna-list-page max-w-7xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Q&A 목록</h1>

      <div className="flex justify-between items-center mb-6">
        <button onClick={handleCreateQna} className="bg-blue-500 text-white py-2 px-4 rounded shadow hover:bg-blue-600">
          Q&A 작성
        </button>
      </div>

      <FilterSection
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        status={status}
        setStatus={setStatus}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        courseList={courseList}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        studentList={studentList}
      />

      <ul className="qna-list space-y-4">
        {qnaList.map((qna) => (
          <QnaItem key={qna._id} qna={qna} handleDelete={handleDeleteQna} />
        ))}
      </ul>

      <Pagination currentPage={page} totalPages={totalPages} setPage={setPage} />
    </div>
  );
};

export default QnaList;
