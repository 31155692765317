import axios from "../utils/axiosInstance";
import { getAuthorizationHeaders } from "../utils/authHeaders";

export const getLogList = async (status, userId, page, limit) => {
  const { data } = await axios.get("/api/connection-logs", {
    params: { status, userId, page, limit },
    headers: getAuthorizationHeaders(),
  });
  return data;
};
