import axios from "../utils/axiosInstance"; // axiosInstance 설정이 되어 있어야 함
import { getAuthorizationHeaders } from "../utils/authHeaders";

// 카테고리 관련 API
export const getCategories = () =>
  axios.get("/api/categories", {
    headers: getAuthorizationHeaders(),
  });

export const createCategory = (categoryData) =>
  axios.post("/api/categories", categoryData, {
    headers: getAuthorizationHeaders(),
  });

export const updateCategory = (categoryId, categoryData) =>
  axios.put(`/api/categories/${categoryId}`, categoryData, {
    headers: getAuthorizationHeaders(),
  });

export const deleteCategory = (categoryId) =>
  axios.delete(`/api/categories/${categoryId}`, {
    headers: getAuthorizationHeaders(),
  });
