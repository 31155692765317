import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://server.thec0d1ng.com", // 백엔드 API 주소
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
