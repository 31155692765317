import React from "react";
import { FaFileDownload } from "react-icons/fa";
import { format } from "date-fns";
import FileLink from "../../FileLink";

const CommentItem = ({ qnaId, answer, onDownload }) => (
  <li className="bg-gray-50 p-4 rounded-lg shadow-sm">
    <div className="flex justify-between items-center">
      <p className="font-semibold text-gray-800">{answer.user.name}</p>
      <p className="text-xs text-gray-500">{format(new Date(answer.createdAt), "yyyy-MM-dd HH:mm")}</p>
    </div>
    <p className="text-gray-700 mt-2">{answer.content}</p>
    {answer.files && answer.files.length > 0 && (
      <div className="mt-2 space-y-1">
        {answer.files.map((file, index) => (
          <FileLink key={file._id} fileId={file._id} onDownload={(fileId) => onDownload(qnaId, answer._id, fileId, file.filename)} label={`첨부파일 ${index + 1}`} />
        ))}
      </div>
    )}
  </li>
);

export default CommentItem;
