import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserInfo, getCurrentUser } from "../../services/userService"; // API 호출부

const EditProfilePage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("student");
  const [education, setEducation] = useState("");
  const [experience, setExperience] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // 페이지가 로드될 때 사용자 정보를 불러옴
    const fetchData = async () => {
      try {
        const userData = await getCurrentUser();
        console.log(userData);
        setName(userData.user.name);
        setEmail(userData.user.email);
        setRole(userData.user.role);
        setEducation(userData.userRole.education);
        if (userData.user.role === "teacher") {
          setExperience(userData.userRole.experience);
        }
      } catch (error) {
        setErrorMessage("사용자 정보를 불러오는 중 오류가 발생했습니다.");
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = { name, education, experience };

    try {
      await updateUserInfo(updatedData);
      navigate("/"); // 수정 완료 후 프로필 페이지로 이동
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "정보 수정 실패");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-blue-100 to-blue-300">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">내 정보 수정</h1>
        {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-600">이름</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-600">이메일</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-600">교육 정보</label>
            <input
              type="text"
              value={education}
              onChange={(e) => setEducation(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          {role === "teacher" && (
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-600">경력</label>
              <input
                type="text"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          )}
          <button type="submit" className="w-full py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300">
            정보 수정
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfilePage;
