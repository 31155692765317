import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchUpcomingClasses, fetchPastClasses, updateClassNotes, createAssignment, downloadAssignment, submitAssignment } from "../../services/classService";
import { fetchCourseById, fetchCourses } from "../../services/courseService";
import { fetchOngoingAssignmentsByCourseId, fetchPastAssignmentsByCourseId } from "../../services/assignmentService";
import { uploadMaterial, fetchMaterialsByCourse, fetchMaterialContent, deleteMaterial } from "../../services/materialService";
import { getCurrentUser } from "../../services/userService";
import Pagination from "../../components/Pagination";
import MaterialListItem from "../../components/Material/MaterialListItem";
import AssignmentListItem from "../../components/Assignment/AssignmentListItem";
import ClassListItem from "../../components/Class/ClassListItem";
import ClassDetailModal from "../../components/Class/ClassDetailModal";
import ClassCommentModal from "../../components/Class/ClassCommentModal";
import CreateAssignmentModal from "../../components/Assignment/CreateAssignmentModal";
import AssignmentDetailModal from "../../components/Assignment/AssignmentDetailModal";
import AssignmentCommentModal from "../../components/Assignment/AssignmentCommentModal";
import MaterialViewerModal from "../../components/Material/MaterialViewerModal";
import UploadMaterialModal from "../../components/Material/UploadMaterialModal";

const ClassList = React.memo(() => {
  const { id } = useParams();
  const userRole = useMemo(() => localStorage.getItem("role"), []);
  const [course, setCourse] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [pagination, setPagination] = useState({
    upcomingClassesPage: 1,
    pastClassesPage: 1,
    ongoingAssignmentsPage: 1,
    pastAssignmentsPage: 1,
    materialPage: 1,
  });
  const [totalPages, setTotalPages] = useState({
    upcomingClassesPages: 1,
    pastClassesPages: 1,
    ongoingAssignmentsPages: 1,
    pastAssignmentsPages: 1,
    materialPages: 1,
  });
  const [courseOptions, setCourseOptions] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [classes, setClasses] = useState({ upcoming: [], past: [] });
  const [assignments, setAssignments] = useState({ ongoing: [], past: [] });
  const [filterTerms, setFilterTerms] = useState({
    classSearch: "",
    classStartDate: "",
    classEndDate: "",
    assignmentSearch: "",
    assignmentStartDate: "",
    assignmentEndDate: "",
    materialSearch: "",
    materialCategory: "",
  });
  const [modalOpen, setModalOpen] = useState({
    class: false,
    classComment: false,
    assignment: false,
    createAssignment: false,
    assignmentComment: false,
    material: false,
    uploadMaterial: false,
  });
  const [selected, setSelected] = useState({ class: null, assignment: null, material: null });
  const [uploadFile, setUploadFile] = useState(null);
  const [submittedFile, setSubmittedFile] = useState(null);
  const [newAssignment, setNewAssignment] = useState({ title: "", description: "", dueDate: "" });

  useEffect(() => {
    const fetchInitialData = async () => {
      const [user, courseData, courses] = await Promise.all([getCurrentUser(), fetchCourseById(id), fetchCourses()]);
      setCurrentUser(user);
      setCourse(courseData);
      setCourseOptions(courses);
    };
    fetchInitialData();
  }, [id]);

  // API calls
  const loadClasses = useCallback(async () => {
    const [upcomingClasses, pastClasses] = await Promise.all([
      fetchUpcomingClasses(id, pagination.upcomingClassesPage),
      fetchPastClasses(id, pagination.pastClassesPage, filterTerms.classSearch, filterTerms.classStartDate, filterTerms.classEndDate),
    ]);
    setClasses({
      upcoming: upcomingClasses?.classes || [],
      past: pastClasses?.classes || [],
    });
    setTotalPages({
      upcomingClassesPages: upcomingClasses?.totalPages || 1,
      pastClassesPages: pastClasses?.totalPages || 1,
    });
  }, [id, pagination.upcomingClassesPage, pagination.pastClassesPage, filterTerms]);

  const loadAssignments = useCallback(async () => {
    const [ongoingAssignments, pastAssignments] = await Promise.all([
      fetchOngoingAssignmentsByCourseId(id, pagination.ongoingAssignmentsPage),
      fetchPastAssignmentsByCourseId(id, pagination.pastAssignmentsPage, filterTerms.assignmentSearch, filterTerms.assignmentStartDate, filterTerms.assignmentEndDate),
    ]);
    setAssignments({
      ongoing: ongoingAssignments?.assignments || [],
      past: pastAssignments?.assignments || [],
    });
    setTotalPages({
      ongoingAssignmentsPages: ongoingAssignments?.totalPages || 1,
      pastAssignmentsPages: pastAssignments?.totalPages || 1,
    });
  }, [id, pagination.ongoingAssignmentsPage, pagination.pastAssignmentsPage, filterTerms]);

  const loadMaterials = useCallback(async () => {
    const { data } = await fetchMaterialsByCourse(id, pagination.materialPage, filterTerms.materialSearch, filterTerms.materialCategory);
    console.log(data);
    setMaterials(data.materials);
    setTotalPages((prev) => ({ ...prev, ["materialPages"]: data.totalPages || 1 }));
  }, [id, pagination.materialPage, filterTerms.materialSearch, filterTerms.materialCategory]);

  useEffect(() => {
    loadClasses();
  }, [loadClasses]);

  useEffect(() => {
    loadAssignments();
  }, [loadAssignments]);

  useEffect(() => {
    loadMaterials();
  }, [loadMaterials]);

  const handleOpenModal = (type, data = null) => setModalOpen((prev) => ({ ...prev, [type]: true }));
  const handleCloseModal = (type) => setModalOpen((prev) => ({ ...prev, [type]: false }));
  const handlePageChange = (type, page) => setPagination((prev) => ({ ...prev, [type]: page }));
  const handleFileChange = (event) => setSubmittedFile(event.target.files[0]);
  const handleMaterialFileChange = (e) => setUploadFile(e.target.files[0]);

  const handleMaterialUpload = async (materialData, file) => {
    if (!file) return alert("파일을 선택하세요.");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", materialData.fileName);
    formData.append("courseIds", JSON.stringify(materialData.courseIds));
    formData.append("category", materialData.category);
    formData.append("problemId", materialData.problemId);
    formData.append("isPublic", materialData.isPublic);
    await uploadMaterial(formData);
    alert("파일이 성공적으로 업로드되었습니다.");
    // setModalOpen((prev) => ({ ...prev, ["uploadMaterial"]: false }));
    loadMaterials();
  };
  // Java 기초 - 04. Method
  const handleMaterialView = useCallback(async (material) => {
    const content = await fetchMaterialContent(material._id);
    setSelected((prev) => ({ ...prev, material: { ...material, content } }));
    handleOpenModal("material");
  }, []);

  const handleDeleteMaterial = useCallback(async (material) => {
    const content = await deleteMaterial(material._id);
    loadMaterials();
  }, []);

  const handleSaveNotes = async () => {
    await updateClassNotes(selected.class._id, selected.class.notes);
    alert("노트가 저장되었습니다.");
    loadClasses();
    handleCloseModal("class");
  };

  const handleSubmitAssignment = async () => {
    if (!submittedFile) return alert("제출할 파일을 선택해주세요.");
    await submitAssignment(selected.assignment._id, submittedFile);
    alert("과제가 성공적으로 제출되었습니다.");
    handleCloseModal("assignment");
  };

  const handleSearchMaterialChange = (e) => {
    setFilterTerms((prev) => ({ ...prev, ["materialSearch"]: e.target.value }));
    setFilterTerms((prev) => ({ ...prev, ["materialCategory"]: e.target.value }));
    setPagination((prev) => ({ ...prev, ["materialPage"]: 1 }));
  };

  const handleDownloadAssignment = async () => {
    const data = await downloadAssignment(selected.assignment._id);
    const url = window.URL.createObjectURL(new Blob([data], { type: "application/octet-stream" }));
    const link = document.createElement("a");
    link.href = url;
    link.download = selected.assignment.submittedFile || "assignment";
    link.click();
    link.remove();
  };

  const handleCreateAssignment = async () => {
    await createAssignment({ ...newAssignment, classId: selected.class._id, courseId: selected.class.course._id });
    alert("과제가 성공적으로 생성되었습니다!");
    handleCloseModal("createAssignment");
  };

  const updateClasses = (updatedClass) => {
    setClasses((prevClasses) => ({ ...prevClasses, upcomingClasses: prevClasses.upcomingClasses.map((cls) => (cls._id === updatedClass._id ? updatedClass : cls)) }));
    setClasses((prevClasses) => ({ ...prevClasses, pastClasses: prevClasses.pastClasses.map((cls) => (cls._id === updatedClass._id ? updatedClass : cls)) }));
  };

  // 상단 타이틀 섹션
  const renderHeaderSection = () => (
    <div className="max-w-7xl mx-auto bg-gradient-to-r from-indigo-600 to-blue-500 rounded-lg shadow-lg p-10 mb-12 border border-gray-200 transform transition-transform duration-300 hover:scale-105">
      <h1 className="text-5xl font-bold text-white text-center mb-6 tracking-wider leading-tight">{course?.courseName || "수업 정보"}</h1>
      <p className="text-xl text-gray-100 text-center mb-4 leading-relaxed max-w-2xl mx-auto">이곳에서 수업 일정과 과제 현황을 확인하실 수 있습니다. 학습 목표에 맞게 체계적으로 준비해 보세요.</p>
      <p className="text-md text-gray-100 text-center max-w-xl mx-auto mb-6">
        필요한 자료에 언제든지 접근하고, 예정된 수업과 과제 마감일을 한눈에 파악하실 수 있습니다. 학습의 모든 여정을 여기에서 편리하게 관리해 보세요.
      </p>
    </div>
  );

  const renderSearchPagination = (searchTerm, handleSearchChange, totalPages, currentPage, onPageChange) => (
    <>
      <input type="text" placeholder="자료 검색" value={searchTerm} onChange={handleSearchChange} className="w-full border border-gray-300 rounded-lg p-2 mb-6" />
    </>
  );

  // 자료 리스트, 과제 리스트, 수업 리스트 구성
  const renderSection = (title, children) => (
    <section className="bg-gradient-to-r from-gray-50 to-white rounded-xl shadow-xl p-10 border border-gray-200 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
      <h2 className="text-4xl font-bold text-gray-800 text-center tracking-tight leading-tight mb-8 border-b-2 border-indigo-400 pb-4">{title}</h2>
      <div className="px-4 sm:px-4 md:px-4 lg:px-4">{children}</div>
    </section>
  );

  const renderPagination = (term, setTerm) => (
    <>
      <input type="text" value={term} onChange={(e) => setTerm(e.target.value)} className="border border-gray-300 rounded-lg p-2" placeholder="검색" />
    </>
  );

  const renderList = (items, ItemComponent, emptyMessage, totalPages, page, onPageChange, props) => {
    return (
      <>
        <ul className="space-y-4">{items.length ? items.map((item, idx) => <ItemComponent key={idx} item={item} {...props} />) : <p>{emptyMessage}</p>}</ul>
        {totalPages && totalPages > 1 && <Pagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />}
      </>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-10">
      {/* 타이틀 영역 */}
      {renderHeaderSection()}

      {/* 메인 콘텐츠 */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 py-8">
        {/* 강의 자료 섹션 */}
        {renderSection(
          "강의 자료",
          <>
            {userRole === "teacher" && (
              <div className="flex justify-end mb-6">
                <button
                  onClick={() => setModalOpen((prev) => ({ ...prev, ["uploadMaterial"]: true }))}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded-lg shadow-md transition"
                >
                  자료 등록
                </button>
              </div>
            )}
            {/* 검색 및 필터링 섹션 */}
            <div className="flex flex-col lg:flex-row items-center gap-4 mb-6">
              <input
                type="text"
                placeholder="자료 검색"
                value={filterTerms.search}
                onChange={(e) => setFilterTerms((prev) => ({ ...prev, materialSearch: e.target.value }))}
                className="border border-gray-300 rounded-lg p-3 shadow-md focus:ring-2 focus:ring-indigo-500 transition"
              />
              <select
                value={filterTerms.category}
                onChange={(e) => setFilterTerms((prev) => ({ ...prev, materialCategory: e.target.value }))}
                className="border border-gray-300 rounded-lg p-3 shadow-md focus:ring-2 focus:ring-indigo-500 transition"
              >
                <option value="">카테고리 필터</option>
                <option value="Lecture">강의</option>
                <option value="Problem">문제</option>
                {/* <option value="Solution">풀이</option> */}
                <option value="Project">프로젝트</option>
                <option value="Reference">참고자료</option>
                <option value="Practice">연습자료</option>
                <option value="Exam Prep">시험 준비</option>
                <option value="Study Plan">학습 계획</option>
              </select>
            </div>
            {renderList(
              materials,
              MaterialListItem,
              "등록된 자료가 없습니다",
              totalPages.materialPages || 1,
              pagination.materialPage,
              (page) => setPagination((prev) => ({ ...prev, materialPage: page })),
              { handleMaterialView, handleDeleteMaterial, userRole }
            )}
          </>
        )}

        {/* 과제 목록 섹션 */}
        {renderSection(
          "과제 목록",
          <>
            <h3 className="text-xl mb-4">진행 중인 과제</h3>
            {renderList(assignments.ongoing, AssignmentListItem, "진행 중인 과제가 없습니다", { setSelected, setModalOpen })}
            <h3 className="text-xl mt-8 mb-4">지난 과제</h3>
            {renderPagination(filterTerms.assignmentSearch, (value) => setFilterTerms((prev) => ({ ...prev, assignmentSearch: value })))}
            {renderList(
              assignments.past,
              AssignmentListItem,
              "등록된 지난 과제가 없습니다",
              totalPages.pastAssignmentsPages,
              pagination.pastAssignmentsPage,
              (page) => setPagination((prev) => ({ ...prev, pastAssignmentsPage: page })),
              { setSelected, setModalOpen }
            )}
          </>
        )}
      </div>

      <div className="max-w-7xl mx-auto bg-white rounded-xl shadow-lg p-8 mt-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 tracking-tight border-b-4 border-gray-300 pb-4">수업 목록</h1>

        {/* 다가오는 수업 섹션 */}
        {renderSection(
          "다가오는 수업",
          <>
            {renderList(
              classes.upcoming,
              ClassListItem,
              totalPages.upcomingClassesPages,
              pagination.upcomingClassesPage,
              "등록된 다가오는 수업이 없습니다",
              (page) => setPagination((prev) => ({ ...prev, upcomingClassesPage: page })),
              {
                userRole,
                setSelected,
                setModalOpen,
              }
            )}
          </>
        )}

        {/* 지난 수업 섹션 */}
        {renderSection(
          "지난 수업",
          <>
            {/* 필터 섹션 */}
            <div className="flex items-center gap-4 mb-6">
              <input
                type="text"
                placeholder="강의 노트 검색"
                value={filterTerms.classSearch}
                onChange={(e) => setFilterTerms((prev) => ({ ...prev, classSearch: e.target.value }))}
                className="flex-1 border border-gray-300 rounded-lg p-3 text-sm placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-200 ease-in-out shadow-sm"
              />
              <input
                type="date"
                value={filterTerms.classStartDate}
                onChange={(e) => setFilterTerms((prev) => ({ ...prev, classStartDate: e.target.value }))}
                className="w-36 border border-gray-300 rounded-lg p-3 text-sm text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-200 ease-in-out shadow-sm"
              />
              <input
                type="date"
                value={filterTerms.classEndDate}
                onChange={(e) => setFilterTerms((prev) => ({ ...prev, classEndDate: e.target.value }))}
                className="w-36 border border-gray-300 rounded-lg p-3 text-sm text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-200 ease-in-out shadow-sm"
              />
            </div>

            {/* 지난 수업 목록 */}
            {renderList(
              classes.past,
              ClassListItem,
              "등록된 지난 수업이 없습니다",
              totalPages.pastClassesPages,
              pagination.pastClassesPage,
              (page) => setPagination((prev) => ({ ...prev, pastClassesPage: page })),
              {
                userRole,
                setSelected,
                setModalOpen,
              }
            )}
          </>
        )}
      </div>

      {modalOpen.class && selected.class && <ClassDetailModal modalOpen={modalOpen} setModalOpen={setModalOpen} selected={selected} handleSaveNotes={handleSaveNotes} />}
      {modalOpen.createAssignment && selected.class && (
        <CreateAssignmentModal modalOpen={modalOpen} setModalOpen={setModalOpen} newAssignment={newAssignment} setNewAssignment={setNewAssignment} handleCreateAssignment={handleCreateAssignment} />
      )}
      {modalOpen.assignment && selected.assignment && (
        <AssignmentDetailModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          selected={selected}
          handleDownloadAssignment={handleDownloadAssignment}
          handleSubmitAssignment={handleSubmitAssignment}
        />
      )}
      {modalOpen.assignmentComment && selected.assignment && <AssignmentCommentModal modalOpen={modalOpen} setModalOpen={setModalOpen} selected={selected} currentUser={currentUser} />}
      {modalOpen.classComment && selected.class && <ClassCommentModal modalOpen={modalOpen} setModalOpen={setModalOpen} selected={selected} currentUser={currentUser} />}
      {modalOpen.material && selected.material && <MaterialViewerModal modalOpen={modalOpen} setModalOpen={setModalOpen} selected={selected} />}
      {modalOpen.uploadMaterial && <UploadMaterialModal modalOpen={modalOpen} onUpload={handleMaterialUpload} setModalOpen={setModalOpen} courseOptions={courseOptions} />}
    </div>
  );
});

export default ClassList;
