import React from "react";
import Modal from "../Modal";
import ClassComments from "./ClassComments";

const ClassCommentModal = ({ modalOpen, setModalOpen, selected, currentUser }) => {
  return (
    <Modal isOpen={modalOpen.commentClass} onClose={() => setModalOpen((prev) => ({ ...prev, ["commentClass"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-4">
        <h2 className="text-3xl font-semibold text-gray-800 text-center">
          {new Date(selected.class.schedule.start).toLocaleDateString()} {new Date(selected.class.schedule.start).toLocaleTimeString()}
        </h2>
        <ClassComments classId={selected.class._id} user={currentUser} />
      </div>
    </Modal>
  );
};

export default ClassCommentModal;
