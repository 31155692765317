import React from "react";

const FolderTree = ({ folders, onSelectFolder }) => {
  const renderTree = (folder) => (
    <li key={folder._id}>
      <button onClick={() => onSelectFolder(folder._id)}>{folder.name}</button>
      {folder.subFolders && folder.subFolders.length > 0 && <ul>{folder.subFolders.map(renderTree)}</ul>}
    </li>
  );

  return <ul className="folder-tree">{folders.map(renderTree)}</ul>;
};

export default FolderTree;
