// src/pages/FolderManagementPage.js

import React, { useState, useEffect } from "react";
import { getTopLevelFolders, getFolderContents, createFolder, updateFolder, deleteFolder, addContentToFolder } from "../../services/folderService";
import { getCategories, createCategory, updateCategory, deleteCategory } from "../../services/categoryService";
import FolderTree from "../../components/Folder/FolderTree";
import FolderModal from "../../components/Folder/FolderModal";
import ContentModal from "../../components/Folder/ContentModal";
import CategoryModal from "../../components/Category/CategoryModal";

const FolderManagementPage = () => {
  const [folders, setFolders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [parentFolder, setParentFolder] = useState(null);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  useEffect(() => {
    loadTopLevelFolders();
    loadCategories();
  }, []);

  const loadTopLevelFolders = async () => {
    const response = await getTopLevelFolders();
    setFolders(response.data);
  };

  const loadCategories = async () => {
    const response = await getCategories();
    setCategories(response.data);
  };

  const handleFolderSelect = async (folderId) => {
    const { data } = await getFolderContents(folderId);
    setSelectedFolder(data);
  };

  const handleCreateFolder = async (newFolderData) => {
    await createFolder({ ...newFolderData, parentFolder });
    loadTopLevelFolders();
    closeFolderModal();
  };

  const handleUpdateFolder = async (folderId, updatedData) => {
    await updateFolder(folderId, updatedData);
    loadTopLevelFolders();
  };

  const handleDeleteFolder = async (folderId) => {
    await deleteFolder(folderId);
    loadTopLevelFolders();
  };

  const handleAddContent = async (contentData) => {
    await addContentToFolder(selectedFolder?._id, contentData);
    handleFolderSelect(selectedFolder?._id);
    setIsContentModalOpen(false);
  };

  const handleCreateCategory = async (categoryData) => {
    await createCategory(categoryData);
    loadCategories();
    closeCategoryModal();
  };

  const handleUpdateCategory = async (categoryId, updatedData) => {
    await updateCategory(categoryId, updatedData);
    loadCategories();
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      loadCategories();
    } catch {
      alert("해당 카테고리를 사용하는 폴더가 있어 삭제할 수 없습니다.");
    }
  };

  const openFolderModal = (folder = null, isParent = false) => {
    setParentFolder(isParent ? folder?._id : null);
    setSelectedFolder(folder);
    setIsFolderModalOpen(true);
  };

  const closeFolderModal = () => {
    setIsFolderModalOpen(false);
    setParentFolder(null);
  };

  const openContentModal = () => setIsContentModalOpen(true);
  const closeContentModal = () => setIsContentModalOpen(false);

  const openCategoryModal = (category = null) => {
    setSelectedCategory(category);
    setIsCategoryModalOpen(true);
  };

  const closeCategoryModal = () => setIsCategoryModalOpen(false);

  return (
    <div className="p-6 space-y-8">
      <header>
        <h1 className="text-3xl font-semibold">폴더 및 카테고리 관리</h1>
      </header>

      <section className="category-section space-y-4">
        <header className="flex justify-between items-center">
          <h2 className="text-2xl font-medium">카테고리 목록</h2>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={() => openCategoryModal()}>
            새 카테고리 추가
          </button>
        </header>
        <ul className="mt-4 space-y-2">
          {categories.map((category) => (
            <li key={category._id} className="flex justify-between items-center bg-gray-100 p-3 rounded-md">
              <span>{category.name}</span>
              <div className="space-x-2">
                <button onClick={() => openCategoryModal(category)} className="bg-gray-500 text-white px-3 py-1 rounded-md">
                  수정
                </button>
                <button onClick={() => handleDeleteCategory(category._id)} className="bg-red-500 text-white px-3 py-1 rounded-md">
                  삭제
                </button>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <section className="flex">
        <FolderTree folders={folders} onSelectFolder={handleFolderSelect} />
        <div className="flex-grow ml-6">
          {selectedFolder ? (
            <div className="folder-details space-y-4">
              <header className="flex justify-between items-center">
                <h2 className="text-2xl font-medium">{selectedFolder.folderName}</h2>
                <div className="space-x-2">
                  <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={openContentModal}>
                    자료 추가
                  </button>
                  <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={() => openFolderModal(selectedFolder, true)}>
                    하위 폴더 추가
                  </button>
                  <button className="bg-gray-500 text-white px-4 py-2 rounded-md" onClick={() => openFolderModal(selectedFolder)}>
                    수정
                  </button>
                  <button className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={() => handleDeleteFolder(selectedFolder._id)}>
                    삭제
                  </button>
                </div>
              </header>

              <div className="mt-4">
                <h3 className="text-lg font-medium">강의 목록</h3>
                <ul className="list-disc pl-6 space-y-1">
                  {(selectedFolder?.courses ?? []).map((course) => (
                    <li key={course._id}>{course.courseName}</li>
                  ))}
                </ul>
                <h3 className="text-lg font-medium mt-4">자료 목록</h3>
                <ul className="list-disc pl-6 space-y-1">
                  {(selectedFolder?.materials ?? []).map((material) => (
                    <li key={material._id}>
                      {material.fileName} - {material.category}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <p>폴더를 선택하세요.</p>
              <button className="bg-green-500 text-white px-4 py-2 rounded-md mt-4" onClick={() => openFolderModal(null, true)}>
                최상위 폴더 추가
              </button>
            </div>
          )}
        </div>
      </section>

      <FolderModal isOpen={isFolderModalOpen} onClose={closeFolderModal} onSubmit={handleCreateFolder} selectedFolder={selectedFolder} />
      <ContentModal isOpen={isContentModalOpen} onClose={closeContentModal} onSubmit={handleAddContent} />
      <CategoryModal
        isOpen={isCategoryModalOpen}
        onClose={closeCategoryModal}
        onSubmit={selectedCategory ? (data) => handleUpdateCategory(selectedCategory._id, data) : handleCreateCategory}
        selectedCategory={selectedCategory}
      />
    </div>
  );
};

export default FolderManagementPage;
