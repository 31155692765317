// src/services/studentService.js

import axios from "../utils/axiosInstance";

export const fetchOngoingAssignments = async () => {
  const { data } = await axios.get("/api/student/ongoing-assignments", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};

export const fetchUpcomingClasses = async () => {
  const { data } = await axios.get("/api/student/upcoming-classes", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};

export const fetchReceivedProposals = async () => {
  const { data } = await axios.get("/api/student/proposals", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  console.log(data);
  return data;
};

export const fetchOngoingCourses = async () => {
  const { data } = await axios.get("/api/student/ongoing-courses", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};

export const fetchQnas = async () => {
  const { data } = await axios.get("/api/student/qnas", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
};
