import axios from "../utils/axiosInstance";

export const fetchSchedules = async (courseId) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`/api/schedules/${courseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

// 교사 스케줄 목록 조회 (전월, 현재 월, 익월)
export const fetchTeacherSchedules = async (selectedDate) => {
  const token = localStorage.getItem("token");
  const formattedDate = selectedDate.toISOString().split("T")[0]; // 날짜를 ISO 형식으로 변환

  const response = await axios.get(`/api/schedules/teacher?date=${formattedDate}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

// 학생 스케줄 목록 조회 (전월, 현재 월, 익월)
export const fetchStudentSchedules = async (selectedDate) => {
  const token = localStorage.getItem("token");
  const formattedDate = selectedDate.toISOString().split("T")[0]; // 날짜를 ISO 형식으로 변환

  const response = await axios.get(`/api/schedules/student?date=${formattedDate}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

// 일정 수정 요청 서비스 함수
export const createPersonalSchedule = async (schedule, googleCalendarId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch("/api/schedules/personal", {
      title: schedule.title,
      start: schedule.start,
      end: schedule.end,
      repeat: schedule.repeat,
      repeatCount: schedule.repeatCount,
      googleCalendarId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("일정 수정 요청 실패:", error);
    throw error;
  }
};

// 일정 수정 요청 서비스 함수
export const requestScheduleModification = async (scheduleId, newStart, newEnd) => {
  console.log(scheduleId, newStart, newEnd);
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch(
      "/api/schedules/modify-request",
      {
        scheduleId,
        newStart,
        newEnd,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("일정 수정 요청 실패:", error);
    throw error;
  }
};

// 일정 수정 승인/거절 서비스 함수
export const confirmScheduleModification = async (scheduleId, confirmed) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch(
      "/api/schedules/confirm-modification",
      {
        scheduleId,
        confirmed,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("일정 수정 승인/거절 실패:", error);
    throw error;
  }
};

// 일정 수정 취소 서비스 함수
export const cancelScheduleModification = async (scheduleId) => {
  console.log(scheduleId);
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch(
      "/api/schedules/cancel-modification",
      {
        scheduleId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("수정 취소 실패:", error);
    throw error; // 에러를 발생시켜서 호출한 곳에서 처리하도록 함
  }
};

export const deletePersonalSchedule = async (scheduleId) => {
  try {
    const token = localStorage.getItem("token");
    console.log(token);
    return await axios.delete(`/api/schedules/personal/${scheduleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("수정 취소 실패:", error);
    throw error; // 에러를 발생시켜서 호출한 곳에서 처리하도록 함
  }
};

// 일정 수정 요청 서비스 함수
export const modifyPersonalSchedule = async (event) => {
  console.log(event);
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch(
      `/api/schedules/personal/${event.id}`,
      {
        title: event.title,
        newStart: event.start,
        newEnd: event.end,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("일정 수정 요청 실패:", error);
    throw error;
  }
};