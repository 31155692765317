// frontend/src/firebase-config.js
import firebase, { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyApna9jh3fOsiEUo-1nSnHV_oBwOuiA2Mc",
  authDomain: "weather-201cb.firebaseapp.com",
  projectId: "weather-201cb",
  storageBucket: "weather-201cb.firebasestorage.app",
  messagingSenderId: "644027927485",
  appId: "1:644027927485:web:7285ab1f1f63889979a0d4",
  measurementId: "G-BZ5NTW3MSC",
};

// Firebase 앱 초기화
const app = initializeApp(firebaseConfig);

// Firebase Cloud Messaging 초기화
const messaging = getMessaging(app);

export { app, messaging };
