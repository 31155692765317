import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import axios from "../utils/axiosInstance";

const GoogleLoginButton = ({ onLoginSuccess }) => {
    const [authUrl, setAuthUrl] = useState("");
  
    const fetchAuthUrl = async () => {
      try {
        const response = await axios.get("/api/auth/google");
        console.log(response);
        setAuthUrl(response.data.url);
      } catch (error) {
        console.error("Error fetching Google auth URL:", error);
      }
    };
//   const handleLogin = useGoogleLogin({
//     onSuccess: (tokenResponse) => onLoginSuccess(tokenResponse.access_token),
//     onError: (error) => console.error("Google 로그인 실패:", error),
//     scope: "https://www.googleapis.com/auth/calendar",
//   });

  return (
    <>
        <button onClick={fetchAuthUrl} className="bg-blue-500 text-white py-2 px-4 rounded">
        Google Calendar 연동
        </button>
        {authUrl && (
            <a href={authUrl} target="_blank" rel="noopener noreferrer" className="text-blue-700 underline">
            Google로 로그인
            </a>
        )}
    </>
    // <GoogleLogin
    //       onSuccess={credentialResponse => onLoginSuccess(credentialResponse)}
    //       onError={() => {
    //         console.log('Login Failed');
    //       }}
    //       scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email"
    //       />
  );
};

export default GoogleLoginButton;
