import React, { useState, useEffect } from "react";
import { postQna } from "../../services/qnaService";
import { fetchCourses } from "../../services/courseService";
import { useNavigate } from "react-router-dom";
import CourseSelector from "../../components/Qna/Write/CourseSelector";
import StudentSelector from "../../components/Qna/Write/StudentSelector";
import FileUploader from "../../components/Qna/FileUploader";

const QnaWrite = () => {
  const [courseList, setCourseList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [questionText, setQuestionText] = useState("");
  const [hashtagInput, setHashtagInput] = useState("");
  const [fileAttachments, setFileAttachments] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const courses = await fetchCourses();
        setCourseList(courses);
      } catch (error) {
        console.error("Failed to load courses:", error);
      }
    };
    loadCourses();
  }, []);

  const handleSubmit = async () => {
    if (!selectedCourse || !questionText.trim()) {
      alert("과목과 질문을 입력해야 합니다.");
      return;
    }

    const formData = new FormData();
    formData.append("course", selectedCourse._id);
    formData.append("question", questionText);
    formData.append("hashtags", hashtagInput);
    if (localStorage.getItem("role") === "teacher" && selectedStudent) {
      formData.append("student", selectedStudent._id);
    }
    fileAttachments.forEach((file) => formData.append("files", file));

    try {
      await postQna(formData);
      alert("QnA가 성공적으로 작성되었습니다.");
      navigate("/qnas");
    } catch (error) {
      console.error("Failed to submit QnA:", error);
    }
  };

  return (
    <div className="qna-write-page max-w-3xl mx-auto p-8 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">Q&A 작성</h1>

      <CourseSelector courseList={courseList} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} setStudentList={setStudentList} />

      {localStorage.getItem("role") === "teacher" && selectedCourse && <StudentSelector studentList={studentList} selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />}

      <div className="mb-6">
        <label className="block text-gray-700 font-medium mb-2">질문</label>
        <textarea
          value={questionText}
          onChange={(e) => setQuestionText(e.target.value)}
          className="border border-gray-300 rounded-lg p-3 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
          rows="5"
          placeholder="질문을 입력하세요"
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 font-medium mb-2">해시태그 (쉼표로 구분)</label>
        <input
          type="text"
          value={hashtagInput}
          onChange={(e) => setHashtagInput(e.target.value)}
          className="border border-gray-300 rounded-lg p-3 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
          placeholder="예: #자바 #프로그래밍"
        />
      </div>

      <FileUploader fileAttachments={fileAttachments} setFileAttachments={setFileAttachments} />

      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition ease-in-out duration-150 shadow-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
      >
        Q&A 작성하기
      </button>
    </div>
  );
};

export default QnaWrite;
