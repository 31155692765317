import React, { useEffect } from "react";

const Modal = ({ isOpen, onClose, children, width = "lg" }) => {
  // esc 키 이벤트 핸들러 추가
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // 모달이 열려 있을 때만 이벤트 리스너 등록
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }
    // 모달이 닫히거나 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className={`bg-white rounded-lg shadow-xl w-full max-w-${width} relative p-6`}>
        {/* 닫기 버튼 */}
        <button onClick={onClose} className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 focus:outline-none transition duration-300 ease-in-out" aria-label="Close modal">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-800">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* 모달 내용 */}
        <div className="text-center">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
