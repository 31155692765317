import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import { fetchProblems } from "../../services/materialService";

const UploadMaterialModal = ({ modalOpen, setModalOpen, onUpload, courseOptions }) => {
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState({ id: "", name: "" });
  const [problemOptions, setProblemOptions] = useState([]);
  const [problemSearch, setProblemSearch] = useState(""); // Problem 검색어 상태 추가
  const [category, setCategory] = useState("");
  const [isPublic, setIsPublic] = useState(true);

  const handleFileChange = (event) => setSelectedFile(event.target.files[0]);

  const handleCourseSelection = (event) => {
    const options = event.target.options;
    const selectedCourses = [];
    for (const option of options) {
      if (option.selected) {
        selectedCourses.push(option.value);
      }
    }
    setSelectedCourses(selectedCourses);
  };

  const handleProblemSelection = (e) => {
    const selectedOption = problemOptions.find((problem) => problem._id === e.target.value);
    if (selectedOption) {
      setSelectedProblem({ id: selectedOption._id, name: selectedOption.fileName });
      setFileName(selectedOption.fileName);
    }
  };

  const handleSubmit = () => {
    if (!fileName || !selectedFile || (category !== "Solution" && selectedCourses.length === 0)) {
      alert("모든 필드를 입력해 주세요.");
      return;
    }

    const materialData = {
      fileName,
      courseIds: selectedCourses,
      category,
      isPublic,
      problemId: category === "Solution" ? selectedProblem.id : null,
    };
    onUpload(materialData, selectedFile);
  };

  // 카테고리가 Solution일 경우, 검색어에 맞는 Problem 목록 가져오기
  useEffect(() => {
    const loadProblems = async () => {
      if (category === "Solution") {
        try {
          const { data } = await fetchProblems(problemSearch);
          setProblemOptions(data.materials);
        } catch (error) {
          console.error("Problem 목록 로드 실패.", error);
        }
      }
    };
    loadProblems();
  }, [category, problemSearch]);

  return (
    <Modal isOpen={modalOpen.uploadMaterial} onClose={() => setModalOpen((prev) => ({ ...prev, ["uploadMaterial"]: false }))} width="lg">
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">강의 자료 등록</h2>
        <select value={category} onChange={(e) => setCategory(e.target.value)} className="w-full border border-gray-300 rounded-lg p-3 mb-4">
          <option value="">카테고리 선택</option>
          <option value="Lecture">강의</option>
          <option value="Problem">문제</option>
          <option value="Solution">풀이</option>
          <option value="Project">프로젝트</option>
          <option value="Reference">참고자료</option>
          <option value="Practice">연습자료</option>
          <option value="Exam Prep">시험 준비</option>
          <option value="Study Plan">학습 계획</option>
        </select>
        <input type="text" placeholder="파일명 입력" value={fileName} onChange={(e) => setFileName(e.target.value)} className="w-full border border-gray-300 rounded-lg p-3 mb-4 h-14" />
        {category === "Solution" ? (
          <>
            <input type="text" placeholder="문제 검색" value={problemSearch} onChange={(e) => setProblemSearch(e.target.value)} className="w-full border border-gray-300 rounded-lg p-3 mb-4" />
            <select value={selectedProblem.id} onChange={handleProblemSelection} className="w-full border border-gray-300 rounded-lg p-3 mb-4">
              <option value="">문제 선택</option>
              {problemOptions.map((problem) => (
                <option key={problem._id} value={problem._id}>
                  {problem.fileName}
                </option>
              ))}
            </select>
          </>
        ) : (
          <select multiple onChange={handleCourseSelection} className="w-full border border-gray-300 rounded-lg p-3 mb-4" style={{ height: "300px" }}>
            {courseOptions.map((course) => (
              <option key={course._id} value={course._id}>
                {course.courseName}
              </option>
            ))}
          </select>
        )}
        <div className="flex items-center mb-4">
          <input type="checkbox" checked={isPublic} onChange={() => setIsPublic(!isPublic)} className="mr-2" />
          <label>공개 설정</label>
        </div>
        <input type="file" onChange={handleFileChange} className="w-full border border-gray-300 rounded-lg p-2 mb-4" />
        <button onClick={handleSubmit} className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
          자료 업로드
        </button>
      </div>
    </Modal>
  );
};

export default UploadMaterialModal;
