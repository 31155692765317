import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import Navbar from "../components/Navbar"; // Navbar import 추가
import LandingPage from "../pages/LandingPage";
import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import TeacherDashboard from "../pages/Dashboard/TeacherDashboard";
import StudentDashboard from "../pages/Dashboard/StudentDashboard";
import CourseList from "../pages/Course/CourseList";
import CreateCourse from "../pages/Course/CreateCourse";
import EditCourse from "../pages/Course/EditCourse"; // 추가된 강의 수정 페이지 import
import ClassList from "../pages/Class/ClassList";
import QnaList from "../pages/Qna/QnaList";
import QnaWrite from "../pages/Qna/QnaWrite";
import QnaDetail from "../pages/Qna/QnaDetail";
import ProposalList from "../pages/Proposal/ProposalList";
import CreateProposal from "../pages/Proposal/CreateProposal";
import ScheduleStudent from "../pages/Schedule/ScheduleStudent";
import ScheduleTeacher from "../pages/Schedule/ScheduleTeacher";
import PaymentList from "../pages/Payment/PaymentList";
import CreatePaymentTeacher from "../pages/Payment/CreatePaymentTeacher";
import CreatePaymentStudent from "../pages/Payment/CreatePaymentStudent";
import IncomeReport from "../pages/Report/IncomeReport";
import NextMonthTuition from "../pages/Report/NextMonthTuition";
import ChangePasswordPage from "../pages/User/ChangePasswordPage";
import FolderManagementPage from "../pages/Folder/FolderManagementPage";
import EditProfilePage from "../pages/User/EditProfilePage";
import MaterialLibrary from "../pages/Library/MaterialLibrary";
import ActiveUsersList from "../components/ActiveUsersList"; // src/AppRoutes.js
import ConnectionLogList from "../pages/ConnectionLogs/ConnectionLogList";
import { useAppContext } from "../contexts/AppContext";
import { WebSocketProvider } from "../contexts/WebSocketProvider";
import "react-toastify/dist/ReactToastify.css";
import { initializeNotificationListener } from "../services/notificationService";
import { ToastContainer } from "react-toastify";

const AppRoutes = () => {
  const { token, userRole, executePageFunction } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    initializeNotificationListener((data) => executePageFunction(location.pathname, data));
  }, [token, location.pathname]);

  return (
    <WebSocketProvider>
      <ErrorBoundary>
        {/* Navbar를 추가하고, 로그인 여부 및 역할을 전달 */}
        <Navbar isLoggedIn={!!token} userRole={userRole} />

        <ActiveUsersList isVisible={token && userRole === "teacher"} />

        <Routes>
          {!token && (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </>
          )}

          {/* 역할에 따라 대시보드로 분기 */}
          {token && userRole === "teacher" ? (
            <>
              <Route path="/" element={<TeacherDashboard />} />
              <Route path="/schedule" element={<ScheduleTeacher />} />
              <Route path="/create-course" element={<CreateCourse />} />
              <Route path="/edit-course/:id" element={<EditCourse />} /> {/* 강의 수정 페이지 추가 */}
              <Route path="/create-proposal" element={<CreateProposal />} />
              <Route path="/payments" element={<PaymentList />} />
              <Route path="/create-payment" element={<CreatePaymentTeacher />} />
              <Route path="/income-report" element={<IncomeReport />} />
              <Route path="/next-month-tuition" element={<NextMonthTuition />} />
              <Route path="/courses" element={<CourseList />} />
              <Route path="/qnas" element={<QnaList />} />
              <Route path="/qna/write" element={<QnaWrite />} />
              <Route path="/qnas/:qnaId" element={<QnaDetail />} />
              <Route path="/proposals" element={<ProposalList />} />
              <Route path="/class-list/:id" element={<ClassList />} />
              <Route path="/change-password" element={<ChangePasswordPage />} />
              <Route path="/connection-logs" element={userRole === "teacher" ? <ConnectionLogList /> : <Navigate to="/" />} />
              <Route path="/profile" element={<EditProfilePage />} />
              <Route path="/library" element={<MaterialLibrary />} />
              <Route path="/folders" element={<FolderManagementPage />} />
            </>
          ) : token && userRole === "student" ? (
            <>
              <Route path="/" element={<StudentDashboard />} />
              <Route path="/schedule" element={<ScheduleStudent />} />
              <Route path="/payments" element={<PaymentList />} />
              {/* <Route path="/create-payment" element={<CreatePaymentStudent />} /> */}
              <Route path="/courses" element={<CourseList />} />
              <Route path="/qnas" element={<QnaList />} />
              <Route path="/qna/write" element={<QnaWrite />} />
              <Route path="/qnas/:qnaId" element={<QnaDetail />} />
              <Route path="/proposals" element={<ProposalList />} />
              <Route path="/class-list/:id" element={<ClassList />} />
              <Route path="/change-password" element={<ChangePasswordPage />} />
              <Route path="/profile" element={<EditProfilePage />} />
              <Route path="/library" element={<MaterialLibrary />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
        <ToastContainer position="bottom-right" autoClose={5000} closeOnClick pauseOnHover draggable />
      </ErrorBoundary>
    </WebSocketProvider>
  );
};

export default AppRoutes;
