// src/services/userService.js
import axios from "../utils/axiosInstance";
import { getAuthorizationHeaders } from "../utils/authHeaders";

export const fetchUsers = async () => {
  const response = await axios.get("/api/users", {
    headers: getAuthorizationHeaders(),
  });

  return response.data;
};

export const fetchUserInfo = async (userId) => {
  const response = await axios.get(`/api/users/${userId}`, {
    headers: getAuthorizationHeaders(),
  });

  return response.data;
};

// 사용자 정보 가져오기 함수
export const getCurrentUser = async () => {
  try {
    const response = await axios.get("/api/auth/user", {
      headers: getAuthorizationHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("사용자 정보를 가져오는 중 오류 발생:", error);
    throw error; // 에러를 발생시켜 호출하는 쪽에서 처리하게 함
  }
};

// 비밀번호 수정
export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await axios.put(
      "/api/users/change-password",
      { currentPassword, newPassword },
      {
        headers: getAuthorizationHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("비밀번호 수정 중 오류 발생:", error);
    throw error.response?.data?.message || "비밀번호 수정 실패";
  }
};

// 비밀번호 수정
export const updateUserInfo = async (data) => {
  try {
    const response = await axios.put("/api/users/change-info", data, {
      headers: getAuthorizationHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("회원정보 수정 중 오류 발생:", error);
    throw error.response?.data?.message || "회원정보 수정 실패";
  }
};
