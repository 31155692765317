import axios from "../utils/axiosInstance";

// Google 캘린더 목록 가져오기
export const fetchGoogleCalendars = async () => {
    const token = localStorage.getItem("token");
    const accessToken = localStorage.getItem("accessToken");
    const { data } = await axios.get("/api/google/calendars", {
        // API 경로에서 /api 생략
        headers: {
        Authorization: `Bearer ${token}`,
        "X-Google-Access-Token": accessToken,
        },
    });
    return data.calendars;
};

// Google 캘린더 생성
export const createGoogleCalendar = async (name, color) => {
    const token = localStorage.getItem("token");
    const accessToken = localStorage.getItem("accessToken");
    const { data } = await axios.post("/api/google/calendars", { name, color }, {
        // API 경로에서 /api 생략
        headers: {
            Authorization: `Bearer ${token}`,
            "X-Google-Access-Token": accessToken,
        },
    });
  return data.calendar;
};
