import axios from "../utils/axiosInstance"; // axiosInstance 설정이 되어 있어야 함
import { getAuthorizationHeaders } from "../utils/authHeaders";

export const uploadMaterial = (formData) => {
  const token = localStorage.getItem("token");
  return axios.post("/api/materials/upload", formData, {
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
  });
};

// 자료 조회 서비스 (검색 및 페이지네이션 추가)
export const fetchMaterialsByCourse = async (courseId, page, search, category, limit = 5) => {
  const token = localStorage.getItem("token");
  return axios.get(`/api/materials/course/${courseId}?page=${page}&limit=${limit}&category=${category}&search=${search}`, {
    headers: getAuthorizationHeaders(),
  });
};

// 자료 조회 서비스 (검색 및 페이지네이션 추가)
export const fetchMaterials = async (page = 1, search = "", category = "") => {
  const token = localStorage.getItem("token");
  return axios.get(`/api/materials/course?page=${page}&limit=10&category=${category}&search=${search}`, {
    headers: getAuthorizationHeaders(),
  });
};

// 자료 조회 서비스 (검색 및 페이지네이션 추가)
export const fetchProblems = async (problemSearch) => {
  const token = localStorage.getItem("token");
  return axios.get(`/api/materials/problems?search=${problemSearch}`, {
    headers: getAuthorizationHeaders(),
  });
};

// 자료 조회 서비스 (검색 및 페이지네이션 추가)
export const fetchSolutionsByProblem = async (problemId) => {
  const token = localStorage.getItem("token");
  return axios.get(`/api/materials/solutions/${problemId}`, {
    headers: getAuthorizationHeaders(),
  });
};

// 파일 내용을 읽어오는 서비스 함수
export const fetchMaterialContent = async (materialId) => {
  try {
    const response = await axios.get(`/api/materials/content/${materialId}`);
    return response.data.content;
  } catch (error) {
    throw new Error("파일을 불러오는 중 오류가 발생했습니다.");
  }
};

// QnA 삭제
export const deleteMaterial = async (materialId) => {
  const { data } = await axios.delete(`/api/materials/${materialId}`, {
    headers: getAuthorizationHeaders(),
  });
  return data;
};
