// frontend/src/services/notificationService.js
import axios from "../utils/axiosInstance";
import { messaging } from "../firebase-config";
import { getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

// 알림 권한 요청 및 토큰 가져오기
export const requestNotificationPermission = async (userId) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, { vapidKey: "BGc__kc9YRfp1nb_YBvB_geBa4Lw0puLTxuQgNrKMjd-y9QySX56BhAM1Lj8Ptl92NliD_GszgD1Y-mX3Aefhus" });
      console.log(`token: ${token}`);
      await saveTokenToServer(token, userId);
      console.log("FCM 토큰:", token);
    } else {
      console.log("알림 권한이 거부되었습니다.");
    }
  } catch (error) {
    console.error("알림 권한 요청 실패:", error);
  }
};

// 백엔드에 FCM 토큰 저장
const saveTokenToServer = async (token, userId) => {
  await axios.post("/api/firebase/save-token", { token, userId });
};

export const initializeNotificationListener = (handler) => {
  onMessage(messaging, (payload) => {
    const { title, body } = payload.notification;

    handler(payload.data);
    // 알림 내용으로 토스트 띄우기
    toast.info(`${title}\n${body}`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      // onClick: onClick(payload.data.id),
    });
  });
};
